const axios = require('axios');
const { notify } = require('../utils/notification');

async function requestAccessToken(code, client_id, client_secret) {
  try {
    //return [];
    console.log(process.env.JOBBER_CLIENT_ID, process.env.JOBBER_CLIENT_SECRET);
    console.log(process.env.NODE_ENV);
    var config = {
      method: 'post',
      url: `https://api.getjobber.com/api/oauth/token?client_id=${client_id}&client_secret=${client_secret}&grant_type=authorization_code&code=${code}`,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json'
      }
    };

    const response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        throw error;
      });
    console.log(response);
    return response;
  } catch (error) {
    return notify('danger', 'Error', `${error.message}`);
  }
}

async function refreshAccessToken(refresh_token, client_id, client_secret) {
  try {
    var config = {
      method: 'post',
      url: `https://api.getjobber.com/api/oauth/token?client_id=${client_id}&client_secret=${client_secret}&grant_type=refresh_token&refresh_token=${refresh_token}`,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json'
      }
    };

    const response = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        throw error;
      });
    console.log(response);
    return response;
  } catch (error) {
    return notify('danger', 'Error', `${error.message}`);
  }
}

async function disconnectJobber(access_token) {
  var query = `mutation Disconnect {
        appDisconnect {
        app {
            name
            author
        }
        userErrors {
            message
        }
      }
    }`;

  let config = {
    method: 'POST',
    url: 'https://api.getjobber.com/api/graphql',
    data: JSON.stringify({ query }),
    headers: {
      'Content-Type': 'application/json',
      'X-JOBBER-GRAPHQL-VERSION': '2022-07-22',
      Authorization: `Bearer ${access_token}`
    }
  };

  try {
    const response = await axios(config);

    return { response: 'success' };
  } catch (error) {
    notify('danger', 'Error', `${error.response.data.message}`);
    return { error: error.response.data.message };
  }
}

async function getContactsFromJobber(access_token) {
  var query = `query {
    clients {
      nodes {
        id
        firstName
        lastName
        billingAddress {
            city
            postalCode
            street
            province
        }
      }
      totalCount
    }
  }`;

  let config = {
    method: 'POST',
    url: 'https://api.getjobber.com/api/graphql',
    data: JSON.stringify({ query }),
    headers: {
      'Content-Type': 'application/json',
      'X-JOBBER-GRAPHQL-VERSION': '2022-07-22',
      Authorization: `Bearer ${access_token}`
    }
  };

  try {
    const response = await axios(config);
    console.log(response);
    return response.data.data.clients.nodes;
  } catch (error) {
    notify('danger', 'Error', `${error.response.data.message}`);
    return { error: error.response.data.message };
  }
}

async function createAttachmentNote(access_token, url, clientId) {
  console.log(url);
  var query = `mutation createNote {
    clientCreateNote(
      clientId: "${clientId}",
      input: {
        attachments: {
            url: "${url}.webp"
        }
      }
    ) {
      clientNote {
        message
      }
    }
  }`;

  let config = {
    method: 'POST',
    url: 'https://api.getjobber.com/api/graphql',
    data: JSON.stringify({ query }),
    headers: {
      'Content-Type': 'application/json',
      'X-JOBBER-GRAPHQL-VERSION': '2022-07-22',
      Authorization: `Bearer ${access_token}`
    }
  };

  try {
    const response = await axios(config);
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    notify('danger', 'Error', `${error.response.data.message}`);
    return { error: error.response.data.message };
  }
}

export { requestAccessToken, refreshAccessToken, disconnectJobber, getContactsFromJobber, createAttachmentNote };
