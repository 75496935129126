import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import { login, signInWithGoogle, auth } from '../../api/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
//import '../styles/Login.css';
import { notify } from '../../utils/notification';

function Login(props) {
  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const [user, loading, error] = useAuthState(auth);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    console.log(user);
    if (user) navigate('/');
  }, [user, loading]);

  // const handleLogin = async (event) => {
  //   if (event.charCode === 13) {
  //     let email = emailRef.current.value;
  //     let password = passwordRef.current.value;

  //     if (!email || !password) return notify('danger', 'Missing Fields', 'Please fill out all required fields.');

  //     const response = await login(email, password);
  //     console.log(response);
  //     if (response.error) {
  //       return notify('danger', 'Login Failed', response.error);
  //     }
  //     //return console.log(response);

  //     //localStorage.setItem('user', JSON.stringify(response.success.user));
  //     //dispatch({ type: 'LOGIN', data: response.success.user});
  //     //props.login(response.success.user);
  //     return;
  //   }
  // };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="relative font-sans text-primaryText overflow-hidden w-full h-full">
      <div className="m-auto w-3/4 max-w-sm h-2/5 rounded-md px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <div className="grid grid-cols-1 space-y-5 w-full ml-auto mr-auto m-auto px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <img
            className="inline ml-auto mr-auto w-48 mb-5"
            src={require('../../assets/logo.png')}
          />
          {/* 
          <TextField
            id="standard-basic"
            label="Email"
            variant="standard"
            ref={emailRef}
            spellcheck="false"
            //onKeyPress={handleLogin}
            autoFocus
            className="pt-2 rounded-md  text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full"
          />

          <TextField
            id="standard-adornment-password"
            variant="standard"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          /> */}

          <button
            //onClick={() => handleLogin({ charCode: 13 })}
            // onClick={() =>
            //   loginWithEmailAndPassword(
            //     emailRef.current.value,
            //     passwordRef.current.value
            //   )
            // }
            onClick={signInWithGoogle}
            className="font-medium h-[48px] p-1 text-white bg-[#3F3F3F] shadow-md rounded-md text-lg focus:outline-none outline-none hover:opacity-70 transition duration-300"
          >
            Login
          </button>
          <div className="relative flex py-3 items-center">
            <div className="flex-grow border-t border-gray-400"></div>
            <span className="flex-shrink mx-4 text-gray-400">OR</span>
            <div className="flex-grow border-t border-gray-400"></div>
          </div>
          <button
            onClick={signInWithGoogle}
            className="flex justify-evenly bg-white items-center font-medium p-1 text-[#7B7B7B] bg-none shadow-md rounded-md text-lg focus:outline-none outline-none hover:opacity-70 transition duration-300"
          >
            <img
              className="inline-block w-10 p-1"
              src="https://i.gyazo.com/04ee1d9234e80ea3b593e2e38256cd47.png"
              alt="google icon"
            />
            Sign in with Google
          </button>
          <div className="grid-rows-1 flex flex-wrap content-evenly">
            <button
              //onClick={() => navigate('/register')}
              onClick={signInWithGoogle}
              className="focus:outline-none outline-none hover:opacity-70 transition duration-300 mr-auto ml-auto"
            >
              <text className="buttonText text-xs opacity-60">Create account</text>
            </button>
            <button
              //onClick={() => navigate('/reset-password')}
              className="focus:outline-none outline-none hover:opacity-70 transition duration-300 mr-auto ml-auto"
            >
              <text className="buttonText text-xs opacity-60">Forgot password?</text>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
