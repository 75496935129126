// customMiddleware.js

const customMiddleware = (store) => (next) => (action) => {
  // Get the current state before the action is dispatched
  const currentState = store.getState();

  // Dispatch the action along with the current state
  next({ ...action, prevState: currentState });
};

export default customMiddleware;
