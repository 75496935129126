import React, { useEffect, useState } from 'react';
import { updateDoc, arrayUnion, getDocs, doc, collection, addDoc, limit, startAfter, query, where } from 'firebase/firestore';
import { db } from '../../api/firebase';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../api/user';
import moment from 'moment';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import DeleteDrawing from './components/DeleteDrawing';
import LoadDrawing from './components/LoadDrawing';
import Autocomplete from '@mui/material/Autocomplete';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField, Box, Button } from '@mui/material';
import { getContacts as getContactsFromJobNimbus } from '../../services/jobnimbus';
const { deleteDrawing } = require('../../api/drawing.js');
const { notify } = require('../../utils/notification.js');

export default function History() {
  const auth = useSelector((state) => state.auth);
  const organization = useSelector((state) => state.organization);
  const [drawings, setDrawings] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [contactFilter, setContactFilter] = useState(null);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [selectedFilter, setSelectedFilter] = useState('Everyone');
  const [selectedContact, setSelectedContact] = useState(null);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [contactsFetched, setContactsFetched] = useState(false);
  // Move organizationRef outside the useEffect
  const organizationId = auth.user?.organization;
  const organizationRef = organizationId ? doc(db, 'organizations', organizationId) : null;

  useEffect(async () => {
    console.log(auth.user);
    if (!auth.user) return;

    // const drawingsCollectionRef = collection(organizationRef, 'drawings');
    // const drawingsSnapshot = await getDocs(drawingsCollectionRef);

    // // Define the function to update a document with a delay
    // const updateDocumentWithDelay = async (doc) => {
    //   const drawingData = doc.data();
    //   const displayName = drawingData.contact ? JSON.parse(drawingData.contact)?.display_name : null;

    //   // Add a "name" property to the drawing data
    //   const updatedData = {
    //     ...drawingData,
    //     name: displayName
    //   };

    //   // Update the document in the collection
    //   console.log(updatedData);
    //   if (updatedData.contact === 'null') return;
    //   await updateDoc(doc.ref, updatedData);
    // };

    // // Loop through each document and wait for 500ms between updates
    // for (const doc of drawingsSnapshot.docs) {
    //   await updateDocumentWithDelay(doc);
    //   // Wait for 500ms before processing the next document
    //   await new Promise((resolve) => setTimeout(resolve, 500));
    // }
    // // Wait for all updates to complete
    // await Promise.all(updatePromises);
    // const organizationId = auth.user.organization;

    // Fetch the initial drawings with pagination
    // const organizationRef = doc(db, 'organizations', organizationId);
    // const drawingsCollectionRef = collection(organizationRef, 'drawings');
    // const initialQuery = query(drawingsCollectionRef, limit(itemsPerPage));
    // const drawingsSnapshot = await getDocs(initialQuery);
    // const initialDrawings = drawingsSnapshot.docs.map((doc) => doc.data());

    // Set the initial itemOffset and endOffset correctly
    // setItemOffset(0);
    // setEndOffset(itemsPerPage);

    let apiKey = auth.user.jobNimbusIntegration.apiKey;

    await getContactsFromJobNimbus(apiKey).then((data) => {
      setContacts(data.results.map((e) => ({ ...e, id: e.jnid, address: `${e.address_line1} ${e.city}, ${e.state_text} ${e.zip}` })));
      setFilteredContacts(data.results.map((e) => ({ ...e, id: e.jnid, address: `${e.address_line1} ${e.city}, ${e.state_text} ${e.zip}` })));
      setContactsFetched(true);
    });

    // Update the state with the initial drawings
    // setDrawings(initialDrawings);
    // setCurrentItems(initialDrawings);
  }, [auth.user]);

  // useEffect(() => {
  //   setEndOffset(itemOffset + itemsPerPage);

  //   // Apply the filter to the drawings before slicing based on itemOffset and endOffset
  //   const filteredDrawings = drawings.filter((drawing) => {
  //     if (!contactFilter && !selectedFilter) return true;
  //     if (selectedFilter === 'Everyone' && !contactFilter) return true;

  //     if (selectedFilter === 'Everyone' && contactFilter) {
  //       if (drawing.contact === 'null') return false;
  //       console.log(contactFilter);

  //       const displayName = JSON.parse(drawing.contact).display_name;
  //       console.log(displayName);
  //       return displayName.toLowerCase().includes(contactFilter.toLowerCase());
  //     }

  //     if (!contactFilter && selectedFilter) {
  //       if (drawing.creator === 'null') return false;
  //       const creator = drawing.creator;
  //       return creator.toLowerCase().includes(selectedFilter.toLowerCase());
  //     }
  //   });

  //   setPageCount(Math.ceil(filteredDrawings.length / itemsPerPage));
  //   setCurrentItems(filteredDrawings.slice(itemOffset, itemOffset + itemsPerPage));
  // }, [drawings.length, itemOffset, contactFilter, selectedFilter, itemsPerPage]);

  // useEffect(() => {
  //   // Update current items whenever itemsPerPage changes
  //   const startIndex = page * itemsPerPage;
  //   setCurrentItems(drawings.slice(startIndex, startIndex + itemsPerPage));
  // }, [itemsPerPage]);

  const handleDeleteDrawing = async (e) => {
    try {
      const organizationId = auth.user.organization;

      if (!organizationId) return; // If organizationId is not set, return early

      // Get the reference to the organization's "drawings" subcollection
      const organizationRef = doc(db, 'organizations', organizationId);
      const drawingsCollectionRef = collection(organizationRef, 'drawings');

      // Delete the drawing from the "drawings" subcollection
      await deleteDrawing(drawingsCollectionRef, e.id);

      // Fetch the latest drawings from the organization's "drawings" subcollection
      const drawingsSnapshot = await getDocs(drawingsCollectionRef);
      const latestDrawings = drawingsSnapshot.docs.map((doc) => doc.data());

      // Update the state with the latest drawings
      setDrawings(latestDrawings);
      setCurrentItems(latestDrawings);
    } catch (error) {
      console.error('Error deleting drawing:', error);
    }
  };

  const handleLoadDrawing = (e) => {
    console.log(e);
    window.location = `/?id=${e.id}`;
  };

  // const handleChangePage = async (event, newPage) => {
  //   const newOffset = newPage * itemsPerPage;
  //   setItemOffset(newOffset);
  //   setPage(newPage);

  //   // Fetch the next page of drawings based on the new offset
  //   const lastDoc = drawings[drawings.length - 1];
  //   const nextPageQuery = collection(organizationRef, 'drawings').startAfter(lastDoc).limit(itemsPerPage);
  //   const nextPageSnapshot = await getDocs(nextPageQuery);
  //   const nextPageDrawings = nextPageSnapshot.docs.map((doc) => doc.data());

  //   // Update the state with the new drawings
  //   setDrawings((prevDrawings) => [...prevDrawings, ...nextPageDrawings]);
  // };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleContactFilterChange = (event) => {
    const input = event.target.value.toLowerCase();
    setContactFilter(event.target.value);
    const filteredContacts = contacts.filter((contact) => contact.name.toLowerCase().includes(input));
    setFilteredContacts(filteredContacts);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset the page when the rows per page is changed
  };

  // Function to filter drawings based on the selected filter
  const filterDrawings = () => {
    if (selectedContact) {
      // Show drawings associated with the selected contact
      return drawings.filter((drawing) => drawing.contact === selectedContact.name);
    } else if (selectedFilter === 'Everyone') {
      // Show all drawings
      return drawings;
    } else {
      // Filter drawings to show only those of team members
      const userEmails = [auth.user.email /* Add other team members' emails here */];
      return drawings.filter((drawing) => userEmails.includes(drawing.creator));
    }
  };

  const handleContactSelection = async (event, newValue) => {
    setSelectedContact(newValue);

    if (newValue) {
      console.log(newValue.display_name);
      // Fetch drawings for the selected contact
      const drawingsCollectionRef = collection(organizationRef, 'drawings');
      const queryByContact = query(drawingsCollectionRef, where('name', '==', newValue.display_name));
      const drawingsSnapshot = await getDocs(queryByContact);
      const drawingsData = drawingsSnapshot.docs.map((doc) => doc.data());
      console.log(drawingsData);
      // Update the state with the drawings for the selected contact
      setDrawings(drawingsData);
      setCurrentItems(drawingsData);
    } else {
      // Reset the drawings when no contact is selected
      setDrawings([]);
      setCurrentItems([]);
    }
  };

  // Use the filtered drawings in the rest of the component
  //const filteredDrawings = filterDrawings();

  return (
    <div className="container mx-auto mt-5 lg:mt-5 px-5 scroller pt-[64px]">
      <header className="bg-white h-auto sticky top-0"></header>
      <div className="flex justify-between text-primaryText font-sans font-medium">
        <div className="self-center">
          <span className="font-bold font-sans">Drawing History</span>
        </div>
        <div className="min-w-[200px] text-right flex">
          <Autocomplete
            disabled={!contactsFetched}
            id="search-by-contact"
            className="w-full "
            options={filteredContacts}
            getOptionLabel={(contact) => contact.display_name}
            value={selectedContact || null}
            onChange={handleContactSelection}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by Contact"
                variant="outlined"
                size="small"
              />
            )}
          />
          {/* <select
            value={selectedFilter}
            onChange={handleFilterChange}
            className="ml-2 px-2 py-1 border rounded bg-white text-primaryText"
          >
            <option value="Everyone">Everyone</option>
            {organization?.members?.map((member) => (
              <option value={member.name}>{member.name}</option>
            ))}
  
          </select> */}
        </div>
      </div>
      <div className="divide-solid bg-primaryText h-[.7px] mt-3"></div>
      <div className="mt-10 h-4xl w-full">
        <div className="rounded-xl relative scroller">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Drawing</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Last Updated</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems
                  .sort((a, b) => b.lastUpdated - a.lastUpdated)
                  .map((e, index) => (
                    <TableRow
                      key={index}
                      className="text-primaryText text-xs border-b border-onPrimaryBgSofter"
                    >
                      <TableCell>
                        <Zoom>
                          <img
                            src={e.thumbnail}
                            width="50px"
                            alt={`Drawing ${index + 1}`}
                          />
                        </Zoom>
                      </TableCell>
                      <TableCell className="truncate">
                        <span className="flex justify-left font-sans">{e.contact !== 'null' ? JSON.parse(e.contact).display_name : null}</span>
                      </TableCell>
                      <TableCell>
                        <span className="flex justify-left font-sans">{moment(e.lastUpdated).format('MMMM Do YYYY, h:mm:ss a')}</span>
                      </TableCell>
                      <TableCell>
                        <span className="flex justify-left font-sans">{e.creator || ''}</span>
                      </TableCell>
                      <TableCell>
                        <div className="flex justify-evenly">
                          <LoadDrawing
                            drawing={e}
                            loadDrawing={handleLoadDrawing}
                          />
                          <DeleteDrawing
                            drawing={e}
                            deleteDrawing={handleDeleteDrawing}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Box
          display="flex"
          justifyContent="center"
          mt={3}
        >
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, 50]}
            count={drawings.length}
            rowsPerPage={itemsPerPage}
            page={page}
            //onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </div>
    </div>
  );
}
