import React, { useRef, useEffect, useState, forwardRef } from 'react';

const Legend = forwardRef((props, ref) => {
  const saveData = props.saveData;
  const groupOptions = props.groupOptions;
  const setGroupOptions = props.setGroupOptions;

  const getGroupByColor = (color) => {
    const foundOption = groupOptions.find((option) => option.color === color);
    //console.log(foundOption);
    return foundOption;
  };

  const handleGroupOptionDescriptionChange = (color, newDescription) => {
    console.log(newDescription);
    // Find the index of the group option with the specified color
    const index = groupOptions.findIndex((option) => option.color === color);

    // If the group option with the specified color is found
    if (index !== -1) {
      // Create a new array with the updated group option
      const updatedGroupOptions = [...groupOptions];
      updatedGroupOptions[index] = {
        ...updatedGroupOptions[index],
        description: newDescription
      };

      // Update the state with the new group options array
      return setGroupOptions(updatedGroupOptions);
    }
  };
  const fenceObjects = Object.values(saveData).filter((item) => item.type === 'Fence');

  // Create a set to keep track of unique group colors
  const uniqueGroupColors = new Set();

  // Filter out duplicate group colors and collect legend items
  const legendItems = fenceObjects.reduce((acc, item) => {
    if (!uniqueGroupColors.has(item.group.color)) {
      // If the group color is unique, add it to the set and create a legend item
      uniqueGroupColors.add(item.group.color);
      acc.push({
        color: item.group.color,
        type: 'Fence',
        description: item.group.description
      });
    }
    return acc;
  }, []);

  // Include legend items for Tear-Out and Existing types if they exist
  const tearOutLines = Object.values(saveData).find((item) => item.type === 'Tear-Out');
  const existingLines = Object.values(saveData).find((item) => item.type === 'Existing');

  if (tearOutLines) {
    legendItems.push({ ...tearOutLines, group: { ...tearOutLines.group, description: 'Tear-Out' } });
  }
  if (existingLines) {
    legendItems.push(existingLines);
  }
  const typeImageMap = {
    // Example: 'rectangle': 'https://example.com/rectangle.png',
    // Add more as needed
    Fence: require('../../../assets/fenceLine.png'),
    'Tear-Out': require('../../../assets/tearOutFence.png'),
    Existing: require('../../../assets/existingFenceLine.png')
  };

  return (
    <div
      style={{
        position: 'absolute',
        bottom: 10,
        right: 10,
        backgroundColor: 'white',
        padding: '10px',
        border: '1.2px solid #ccc',
        borderRadius: '5px'
      }}
      id="legend"
    >
      {legendItems.map((item, index) => {
        if (!item) return;

        let type;

        let objectGroup = getGroupByColor(item.color);

        return (
          <div
            key={index}
            style={{ display: 'flex', alignItems: 'center', height: '25px' }}
          >
            {item.type === 'Fence' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                viewBox="0 0 737 170"
                fill="none"
                className="mr-1 align-middle"
              >
                <path
                  d="M147.5 85.5H366H584.5"
                  stroke={item.color}
                  stroke-width="73"
                />
                <circle
                  cx="652"
                  cy="85"
                  r="73"
                  fill="white"
                />
                <circle
                  cx="652"
                  cy="85"
                  r="73"
                  stroke="#D54135"
                  stroke-width="24"
                />
                <circle
                  cx="652"
                  cy="85"
                  r="73"
                  stroke="#D54135"
                  stroke-width="24"
                />
                <circle
                  cx="85"
                  cy="85"
                  r="73"
                  fill="white"
                />
                <circle
                  cx="85"
                  cy="85"
                  r="73"
                  stroke="#D54135"
                  stroke-width="24"
                />
                <circle
                  cx="85"
                  cy="85"
                  r="73"
                  stroke="#D54135"
                  stroke-width="24"
                />
              </svg>
            ) : (
              <img
                src={typeImageMap[item.type]}
                alt={type}
                style={{ marginRight: '5px', width: '50px', height: 'auto' }}
                className="select-none"
              />
            )}
            <GroupOptionDescriptionInput
              initialDescription={objectGroup?.description || item.type}
              onSave={handleGroupOptionDescriptionChange}
              color={item.color}
            />
          </div>
        );
      })}
    </div>
  );
});

export default Legend;

const GroupOptionDescriptionInput = ({ color, initialDescription, onSave }) => {
  const [description, setDescription] = useState(initialDescription);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onSave(color, description); // Save the updated description onBlur
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default action (e.g., form submission)
      event.target.blur(); // Remove focus from the input field
    }
  };

  return (
    <input
      type="text"
      value={description}
      className="ml-2 w-[150px] max-w-[150px] h-[25px] align-middle text-[16px]"
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={() => setIsEditing(true)}
      onKeyPress={handleKeyPress}
      //readOnly={!isEditing} // Make the input readOnly when not editing
    />
  );
};
