import React, { useRef, useEffect, useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import { Stage, Layer, Rect, Text, useStrictMode, Line } from 'react-konva';
import Konva from 'konva';
import { isMobile } from 'react-device-detect';
import Fence from './Fence.js';
import TearOutFence from './TearOutFence.js';
import ExistingFence from './ExistingFence.js';
import Gate from './Gate.js';
import Arrow from './Arrow.js';
import RectWithTransformer from './RectWithTransformer.js';
import CircleWithTransformer from './CircleWithTransformer.js';
import DraggableText from './DraggableText.js';
import { useSketchContext } from '../../../contexts/SketchContext';
import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/icons-material/Undo';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import GridOffIcon from '@mui/icons-material/GridOff';
import GridOnIcon from '@mui/icons-material/GridOn';
import KeyIcon from '@mui/icons-material/Key';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import RedoIcon from '@mui/icons-material/Redo';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Tree from './Tree.js';
import Pool from './Pool.js';
import DraggableImage from './DraggableImage.js';
import DoubleGate from './DoubleGate.js';
import Legend from './Legend';
import Concrete from './Concrete.js';

const KonvaDrawSpace = (props) => {
  //const objects = useSelector((state) => state.sketch.objects);
  //const [objects, setObjects] = useState([]);
  const saveData = useSelector((state) => state.sketch.saveData);
  const history = useSelector((state) => state.sketch.history);
  const historyStep = useSelector((state) => state.sketch.historyStep);
  const dispatch = useDispatch();
  const boxRef = useRef(null);
  const stageRef = useRef(null);
  const layerRef = useRef(null);
  const drawingLayerRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [drawing, setDrawing] = useState(false);
  const [drawingShape, setDrawingShape] = useState(null);
  const [allowDragging, setAllowDragging] = useState(props.currentTool === 'pan');
  const [lastCenter, setLastCenter] = useState(null);
  const [lastDist, setLastDist] = useState(0);
  const [gridVisible, setGridVisible] = useState(true);
  const [gridSize, setGridSize] = useState(20);
  const selected = props.selected;
  const setSelected = props.setSelected;
  const currentTool = props.currentTool;
  const objects = props.objects;
  const setObjects = props.setObjects;
  const groupOptions = props.groupOptions;
  const setGroupOptions = props.setGroupOptions;
  const legendVisible = props.legendVisible;
  const setLegendVisible = props.setLegendVisible;

  useEffect(() => {
    // props.setSaveData((prevState) => ({
    //   ...prevState,
    //   stageRef: stageRef
    // }));

    dispatch({ type: 'UPDATE_STAGE_REF', data: stageRef });

    //setHistory(props.saveData);
    updateKonvaSize();
    //todo: this should run on page resize
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('resize', handleResize);
      //setObjects([]);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('loadObjects', onLoad);

    return () => {
      document.removeEventListener('loadObjects', onLoad);
    };
  }, []);

  useEffect(() => {
    setAllowDragging(currentTool === 'pan');
    const draggingAllowedEvent = new CustomEvent('draggingAllowed', {
      detail: { state: currentTool === 'pan' }
    });

    document.dispatchEvent(draggingAllowedEvent);
  }, [props.currentTool]);

  // useEffect(() => {
  //   //redrawStage();
  //   console.log(groupOptions);
  // }, [groupOptions]);

  // useEffect(() => {
  //   console.log(selected);
  // }, [history.length, props.objects.length, historyStep, selected]);

  const updateKonvaSize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const handleResize = () => {
    // props.setSaveData((prevState) => ({
    //   ...prevState,
    //   stageRef: stageRef
    // }));

    dispatch({ type: 'UPDATE_STAGE_REF', data: stageRef });
    updateKonvaSize();
  };

  const onMouseDown = (e) => {
    if (e.evt.button === 0) {
      startDrawing(stageRef.current.getRelativePointerPosition());
    }
    closePopovers(e);
  };
  const onMouseMove = (e) => {
    if (drawing) {
      updateDrawing(stageRef.current.getRelativePointerPosition());
    }
  };
  const onMouseUp = (e) => {
    //finishDrawing(stageRef.current.getRelativePointerPosition());
    if (drawing) {
      finishDrawing(stageRef.current.getRelativePointerPosition());
    }
  };

  const onTouchStart = (e) => {
    e.evt.preventDefault();
    startDrawing(stageRef.current.getRelativePointerPosition());

    closePopovers(e);
  };
  const onTouchMove = (e) => {
    e.evt.preventDefault();
    if (drawing) {
      updateDrawing(stageRef.current.getRelativePointerPosition());
    } else if (currentTool === 'pan') {
      //pinch zoom

      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];

      if (touch1 && touch2) {
        setAllowDragging(false);

        const p1 = {
          x: touch1.clientX,
          y: touch2.clientY
        };

        const p2 = {
          x: touch2.clientX,
          y: touch2.clientY
        };

        if (!lastCenter) {
          setLastCenter(getCenter(p1, p2));
          return;
        }
        const newCenter = getCenter(p1, p2);
        const dist = getDistance(p1, p2);

        if (!lastDist) {
          setLastDist(dist);
          return;
        }

        const pointTo = {
          x: (newCenter.x - stageRef.current.x()) / stageRef.current.scaleX(),
          y: (newCenter.y - stageRef.current.y()) / stageRef.current.scaleX()
        };

        const scale = stageRef.current.scaleX() * (dist / lastDist);

        stageRef.current.scaleX(scale);
        stageRef.current.scaleY(scale);

        const dx = newCenter.x - lastCenter.x;
        const dy = newCenter.y - lastCenter.y;

        const newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy
        };

        stageRef.current.position(newPos);

        setLastDist(dist);
        setLastCenter(newCenter);
      }
    }
  };
  const onTouchEnd = (e) => {
    e.evt.preventDefault();
    if (drawing) {
      finishDrawing(stageRef.current.getRelativePointerPosition());
    }
    setLastDist(0);
    setLastCenter(null);
    setAllowDragging(currentTool === 'pan');
  };

  const getDistance = (p1, p2) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  };

  const getCenter = (p1, p2) => {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2
    };
  };

  const onContextMenu = (e) => {
    e.evt.preventDefault();
  };

  const onWheel = (e) => {
    const scaleBy = 1.05;
    e.evt.preventDefault();
    const oldScale = stageRef.current.scaleX();
    const pointer = stageRef.current.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stageRef.current.x()) / oldScale,
      y: (pointer.y - stageRef.current.y()) / oldScale
    };
    let direction = e.evt.deltaY < 0 ? 1 : -1;
    if (e.evt.ctrlKey) {
      direction = -direction;
    }

    const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    stageRef.current.scale({ x: newScale, y: newScale });
    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale
    };
    stageRef.current.position(newPos);
  };
  const closePopovers = (e) => {
    if (e.target.id === 'feet' || e.target.id === 'inches') {
      return;
    }
    const closePopoversEvent = new CustomEvent('clearSelections', {
      detail: { target: e.target }
    });
    document.dispatchEvent(closePopoversEvent);
  };

  function checkAngle(position) {
    if (currentTool === 'rectangle' || currentTool === 'circle') {
      return position;
    }

    var points = position;
    // [x1, y1, x2, y2]
    var dy = points[3] - points[1];
    var dx = points[2] - points[0];

    var angle = Math.atan2(dy, dx);
    angle *= 180 / Math.PI;

    //console.log('Angle is: ' + angle);
    if ((-5 <= angle && angle <= 5) || (175 <= angle && angle <= 185) || (-175 >= angle && angle >= -185)) {
      //shape.attrs.points = [points[0], points[1], points[2], points[1]];
      //return (drawingShape.props.points = [points[0], points[1], points[2], points[1]]);
      return (position = [points[0], points[1], points[2], points[1]]);
    } else if ((85 <= angle && angle <= 95) || (-85 >= angle && angle >= -95)) {
      //shape.attrs.points = [points[0], points[1], points[0], points[3]];
      //return (drawingShape.props.points = [points[0], points[1], points[0], points[3]]);
      return (position = [points[0], points[1], points[0], points[3]]);
    }

    return position;
  }

  const startDrawing = (p) => {
    console.log(currentTool);
    if (currentTool === 'pan') return;

    let position = snapPositionToGrid(p);
    let color;
    const id = props.getRandomId();

    let baseProps = {
      key: id,
      id: id,
      points: [position.x, position.y, position.x, position.y],
      x: position.x,
      y: position.y,
      draggable: allowDragging,
      stageRef: stageRef,
      delete: handleDeleteObject,
      updateObject: (e) => dispatch({ type: 'UPDATE_OBJECT', data: e }),
      groupOptions: groupOptions,
      setGroupOptions: setGroupOptions,
      defaultGroup: props.defaultGroup,
      setDefaultGroup: props.setDefaultGroup,
      //objects: objects,
      // setHistory: setHistory,
      // setHistoryStep: setHistoryStep,
      setSelected: setSelected
    };

    if (currentTool === 'fence') {
      const newLine = <Fence {...baseProps}></Fence>;
      setDrawingShape(newLine);
      setDrawing(true);
    } else if (currentTool === 'tearout') {
      const newLine = <TearOutFence {...baseProps}></TearOutFence>;
      setDrawingShape(newLine);
      setDrawing(true);
    } else if (currentTool === 'existing') {
      const newLine = <ExistingFence {...baseProps}></ExistingFence>;
      setDrawingShape(newLine);
      setDrawing(true);
    } else if (currentTool === 'gate') {
      // let { position: newPosition, color: newColor } = snapPositionToNearestFence(p);
      // position = newPosition;
      // color = newColor;
      position = snapPositionToNearestFence(p);

      baseProps = {
        ...baseProps,
        snapGateToFence: snapGateToFence,
        points: [position.x, position.y, position.x, position.y],
        stroke: position.color
      };
      const newLine = <Gate {...baseProps}></Gate>;
      setDrawingShape(newLine);
      setDrawing(true);
    } else if (currentTool === 'double-gate') {
      position = snapPositionToNearestFence(p);

      baseProps = {
        ...baseProps,
        snapGateToFence: snapGateToFence,
        points: [position.x, position.y, position.x, position.y],
        stroke: position.color
      };
      const newLine = <DoubleGate {...baseProps}></DoubleGate>;
      setDrawingShape(newLine);
      setDrawing(true);
    } else if (currentTool === 'arrow') {
      const newArrow = <Arrow {...baseProps} />;

      setDrawingShape(newArrow);
      setDrawing(true);
    } else if (currentTool === 'rectangle') {
      const newRect = <RectWithTransformer {...baseProps} />;
      setDrawingShape(newRect);
      setDrawing(true);
    } else if (currentTool === 'circle') {
      const newCircle = <CircleWithTransformer {...baseProps} />;
      setDrawingShape(newCircle);
      setDrawing(true);
    } else if (currentTool === 'tree') {
      const newTree = <Tree {...baseProps} />;
      setDrawingShape(newTree);
      setDrawing(true);
    } else if (currentTool === 'pool') {
      const newPool = <Pool {...baseProps} />;
      setDrawingShape(newPool);
      setDrawing(true);
    } else if (currentTool === 'concrete') {
      const newConcrete = <Concrete {...baseProps} />;
      setDrawingShape(newConcrete);
      setDrawing(true);
    } else if (currentTool === 'text') {
      baseProps = { ...baseProps, text: '', setSaveData: props.setSaveData };
      const newText = <DraggableText {...baseProps} />;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(newText);
      //props.setCurrentTool('pan');
    }
  };
  const updateDrawing = (p) => {
    //let position = snapPositionToExistingObjects(p);
    let position = snapPositionToGrid(p);

    let baseProps = {
      key: drawingShape.key,
      id: drawingShape.key,
      draggable: allowDragging,
      stageRef: stageRef,
      delete: handleDeleteObject,
      group: drawingShape.group,
      groupOptions: groupOptions,
      setGroupOptions: setGroupOptions,
      defaultGroup: props.defaultGroup,
      setDefaultGroup: props.setDefaultGroup,
      updateObject: (e) => dispatch({ type: 'UPDATE_OBJECT', data: e }),
      //objects: objects,
      // setHistory: setHistory,
      // setHistoryStep: setHistoryStep,
      setSelected: setSelected,
      setObjects: setObjects
    };

    if (currentTool === 'fence') {
      let snapLineStraight = checkAngle([drawingShape.props.points[0], drawingShape.props.points[1], position.x, position.y]);
      baseProps = { ...baseProps, points: snapLineStraight };

      const updatedLine = <Fence {...baseProps}></Fence>;
      setDrawingShape(updatedLine);
      drawingLayerRef.current.draw();
    } else if (currentTool === 'tearout') {
      let snapLineStraight = checkAngle([drawingShape.props.points[0], drawingShape.props.points[1], position.x, position.y]);
      baseProps = { ...baseProps, points: snapLineStraight };
      const updatedLine = <TearOutFence {...baseProps}></TearOutFence>;
      setDrawingShape(updatedLine);
      drawingLayerRef.current.draw();
    } else if (currentTool === 'existing') {
      let snapLineStraight = checkAngle([drawingShape.props.points[0], drawingShape.props.points[1], position.x, position.y]);
      baseProps = { ...baseProps, points: snapLineStraight };
      const updatedLine = <ExistingFence {...baseProps}></ExistingFence>;
      setDrawingShape(updatedLine);
      drawingLayerRef.current.draw();
    } else if (currentTool === 'gate') {
      position = snapPositionToNearestFence(p);
      let snapLineStraight = checkAngle([drawingShape.props.points[0], drawingShape.props.points[1], position.x, position.y]);

      baseProps = { ...baseProps, points: snapLineStraight, snapGateToFence: snapGateToFence, stroke: position.color };
      const updatedLine = <Gate {...baseProps}></Gate>;
      setDrawingShape(updatedLine);
    } else if (currentTool === 'double-gate') {
      console.log('updating double gate');

      position = snapPositionToNearestFence(p);
      let snapLineStraight = checkAngle([drawingShape.props.points[0], drawingShape.props.points[1], position.x, position.y]);

      baseProps = { ...baseProps, points: snapLineStraight, snapGateToFence: snapGateToFence, stroke: position.color };
      const updatedLine = <DoubleGate {...baseProps}></DoubleGate>;
      setDrawingShape(updatedLine);
    } else if (currentTool === 'arrow') {
      baseProps = {
        ...baseProps,
        points: [drawingShape.props.points[0], drawingShape.props.points[1], position.x, position.y]
      };
      const updatedArrow = <Arrow {...baseProps} />;
      setDrawingShape(updatedArrow);
    } else if (currentTool === 'rectangle') {
      baseProps = {
        ...baseProps,
        x: drawingShape.props.x,
        y: drawingShape.props.y,
        width: position.x - drawingShape.props.x,
        height: position.y - drawingShape.props.y
      };
      const updatedRect = <RectWithTransformer {...baseProps} />;
      setDrawingShape(updatedRect);
    } else if (currentTool === 'circle') {
      baseProps = {
        ...baseProps,
        x: drawingShape.props.x,
        y: drawingShape.props.y,
        radius: Math.abs((position.x - drawingShape.props.x) / 2)
      };
      const updatedCircle = <CircleWithTransformer {...baseProps} />;
      setDrawingShape(updatedCircle);
    } else if (currentTool === 'tree') {
      baseProps = {
        ...baseProps,
        x: drawingShape.props.x,
        y: drawingShape.props.y,
        radius: Math.abs((position.x - drawingShape.props.x) / 2)
      };
      const updatedTree = <Tree {...baseProps} />;
      setDrawingShape(updatedTree);
    } else if (currentTool === 'pool') {
      baseProps = {
        ...baseProps,
        x: drawingShape.props.x,
        y: drawingShape.props.y,
        width: position.x - drawingShape.props.x,
        height: position.y - drawingShape.props.y
      };
      const updatedPool = <Pool {...baseProps} />;
      setDrawingShape(updatedPool);
    } else if (currentTool === 'concrete') {
      baseProps = {
        ...baseProps,
        x: drawingShape.props.x,
        y: drawingShape.props.y,
        width: position.x - drawingShape.props.x,
        height: position.y - drawingShape.props.y
      };
      const updatedConcrete = <Concrete {...baseProps} />;
      setDrawingShape(updatedConcrete);
    }
  };
  const finishDrawing = (p) => {
    //let position = snapPositionToExistingObjects(p);

    let position = snapPositionToGrid(p);
    let baseProps = {
      key: drawingShape.key,
      id: drawingShape.key,
      points: drawingShape.props.points,
      draggable: allowDragging,
      stageRef: stageRef,
      snapObjectToExistingObjects: snapObjectToExistingObjects,
      snapPositionToGrid: snapPositionToGrid,
      group: drawingShape.group,
      groupOptions: groupOptions,
      setGroupOptions: setGroupOptions,
      defaultGroup: props.defaultGroup,
      setDefaultGroup: props.setDefaultGroup,
      //setObjects: setObjects,
      //objects: objects,
      setSaveData: props.setSaveData,
      addObjectToSaveData: props.addObjectToSaveData,
      updateObjectInSaveData: props.updateObjectInSaveData,
      delete: handleDeleteObject,
      updateObject: (e) => dispatch({ type: 'UPDATE_OBJECT', data: e }),
      // setHistory: setHistory,
      // setHistoryStep: setHistoryStep,
      setSelected: setSelected
    };

    if (currentTool === 'fence') {
      console.log(baseProps);
      const finishedLine = <Fence {...baseProps}></Fence>;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(finishedLine);
    } else if (currentTool === 'tearout') {
      const finishedLine = <TearOutFence {...baseProps}></TearOutFence>;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(finishedLine);
    } else if (currentTool === 'existing') {
      const finishedLine = <ExistingFence {...baseProps}></ExistingFence>;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(finishedLine);
    } else if (currentTool === 'gate') {
      baseProps = { ...baseProps, snapGateToFence: snapGateToFence };
      const finishedLine = <Gate {...baseProps}></Gate>;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(finishedLine);
      props.setCurrentTool('pan');
    } else if (currentTool === 'double-gate') {
      console.log('finishing double gate');
      baseProps = { ...baseProps, snapGateToFence: snapGateToFence };
      const finishedLine = <DoubleGate {...baseProps}></DoubleGate>;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(finishedLine);
      props.setCurrentTool('pan');
    } else if (currentTool === 'arrow') {
      const finishedArrow = <Arrow {...baseProps} />;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(finishedArrow);
      props.setCurrentTool('pan');
    } else if (currentTool === 'rectangle') {
      baseProps = {
        ...baseProps,
        x: drawingShape.props.x,
        y: drawingShape.props.y,
        width: position.x - drawingShape.props.x,
        height: position.y - drawingShape.props.y
      };
      const finishedRect = <RectWithTransformer {...baseProps} />;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(finishedRect);
      props.setCurrentTool('pan');
    } else if (currentTool === 'circle') {
      baseProps = {
        ...baseProps,
        x: drawingShape.props.x,
        y: drawingShape.props.y,
        radius: Math.abs((position.x - drawingShape.props.x) / 2)
      };
      const finishedCircle = <CircleWithTransformer {...baseProps} />;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(finishedCircle);
      props.setCurrentTool('pan');
    } else if (currentTool === 'tree') {
      baseProps = {
        ...baseProps,
        x: drawingShape.props.x,
        y: drawingShape.props.y,
        radius: Math.abs((position.x - drawingShape.props.x) / 2)
      };
      const finishedTree = <Tree {...baseProps} />;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(finishedTree);
      props.setCurrentTool('pan');
    } else if (currentTool === 'pool') {
      baseProps = {
        ...baseProps,
        x: drawingShape.props.x,
        y: drawingShape.props.y,
        width: position.x - drawingShape.props.x,
        height: position.y - drawingShape.props.y
      };
      const finishedPool = <Pool {...baseProps} />;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(finishedPool);
      props.setCurrentTool('pan');
    } else if (currentTool === 'concrete') {
      baseProps = {
        ...baseProps,
        x: drawingShape.props.x,
        y: drawingShape.props.y,
        width: position.x - drawingShape.props.x,
        height: position.y - drawingShape.props.y
      };
      const finishedConcrete = <Concrete {...baseProps} />;
      setDrawing(false);
      setDrawingShape(null);
      createNewObject(finishedConcrete);
      props.setCurrentTool('pan');
    }
  };

  const createNewObject = (object) => {
    //setObjects([...objects, object]);
    return setObjects((prevState) => {
      return [...prevState, object];
    });

    dispatch({ type: 'CREATE_OBJECT', data: object });
  };

  const snapPositionToExistingObjects = (position) => {
    if (currentTool === 'rectangle' || currentTool === 'circle') {
      return position;
    }
    const targetRect = {
      x: position.x - 10,
      y: position.y - 10,
      width: 20,
      height: 20
    };

    const handles = layerRef.current.find((child) => child.attrs.type === 'dragHandle');

    for (var i = handles.length - 1; i >= 0; i--) {
      if (haveIntersection(handles[i].getClientRect({ relativeTo: layerRef.current }), targetRect)) {
        return { x: handles[i].x(), y: handles[i].y() };
      }
    }

    position = checkAngle(position);

    return position;
  };

  const snapObjectToExistingObjects = (object) => {
    // Get the bounding box of the object
    const targetRect = object.getClientRect({
      relativeTo: layerRef.current
    });

    // Find all drag handles on the layer
    const handles = layerRef.current.find((child) => child.attrs.type === 'dragHandle');

    // Iterate over the handles
    for (let i = handles.length - 1; i >= 0; i--) {
      // Check if the handle intersects with the object
      if (haveIntersection(handles[i].getClientRect({ relativeTo: layerRef.current }), targetRect)) {
        // Snap the object's position to the handle's position
        const snappedPosition = snapPositionToGrid({ x: handles[i].x(), y: handles[i].y() });
        return snappedPosition;
      }
    }

    // If no intersection with handles, snap the object's position to the grid
    const snappedPosition = snapPositionToGrid({ x: object.x(), y: object.y() });
    return snappedPosition;
  };

  const snapPositionToGrid = (position) => {
    const gridSize = 20; // Set your grid size here
    const snappedX = Math.round(position.x / gridSize) * gridSize; // Snap x coordinate
    const snappedY = Math.round(position.y / gridSize) * gridSize; // Snap y coordinate
    return { x: snappedX, y: snappedY };
  };

  const snapGateToFence = (object) => {
    const targetRect = object.getClientRect({
      relativeTo: layerRef.current
    });

    const fences = layerRef.current.find((child) => {
      return child.attrs.type === 'Fence';
    });

    for (let i = fences.length - 1; i >= 0; i--) {
      if (fences[i] === object) {
      } else if (haveIntersection(fences[i].getClientRect({ relativeTo: layerRef.current }), targetRect)) {
        if (!fences[i].attrs.points) continue;
        const fenceX1 = fences[i].attrs.points[0];
        const fenceY1 = fences[i].attrs.points[1];
        const fenceX2 = fences[i].attrs.points[2];
        const fenceY2 = fences[i].attrs.points[3];

        const closestPoint = getClosestPointOnLineSegment(object.attrs.x, object.attrs.y, fenceX1, fenceY1, fenceX2, fenceY2);

        return { x: closestPoint.x, y: closestPoint.y, color: fences[i].attrs.group.color };
      }
    }

    return { x: object.attrs.x, y: object.attrs.y };
  };

  const snapPositionToNearestFence = ({ x, y }) => {
    let closestPoint = { x: x, y: y, color: '' };

    let minDistance = 999999;

    const fences = layerRef.current.find((child) => {
      console.log(child.constructor.name);
      console.log(child.attrs.type);
      return child.constructor.name !== 'Group' && child.attrs.type === 'Fence';
    });
    console.log(fences);
    for (let i = 0; i < fences.length; i++) {
      const fence = fences[i];
      if (!fence.points) continue;
      const fenceX1 = fence.attrs.points[0];
      const fenceY1 = fence.attrs.points[1];
      const fenceX2 = fence.attrs.points[2];
      const fenceY2 = fence.attrs.points[3];

      const point = getClosestPointOnLineSegment(x, y, fenceX1, fenceY1, fenceX2, fenceY2);
      const distance = Math.sqrt((x - point.x) ** 2 + (y - point.y) ** 2);

      if (distance < minDistance) {
        minDistance = distance;
        closestPoint = point;
        closestPoint.color = fence.attrs.group.color;
      }
    }

    return closestPoint;
  };

  const snapPointToLineSegment = (x, y, x1, y1, x2, y2) => {
    let A = x - x1;
    let B = y - y1;
    let C = x2 - x1;
    let D = y2 - y1;

    let dot = A * C + B * D;
    let len_sq = C * C + D * D;
    let param = -1;
    if (len_sq != 0)
      // in case of zero length line
      param = dot / len_sq;

    let xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    } else if (param > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    return { x: xx, y: yy };
  };

  const getClosestPointOnLineSegment = (px, py, x1, y1, x2, y2) => {
    // Calculate the length of the line segment
    const dx = x2 - x1;
    const dy = y2 - y1;
    const segmentLengthSquared = dx * dx + dy * dy;

    // If the segment has zero length, return the first endpoint
    if (segmentLengthSquared === 0) {
      return { x: x1, y: y1 };
    }

    // Calculate the parameter value of the closest point on the line segment
    const t = ((px - x1) * dx + (py - y1) * dy) / segmentLengthSquared;

    // If the closest point is outside of the line segment, return the closest endpoint
    if (t < 0) {
      return { x: x1, y: y1 };
    } else if (t > 1) {
      return { x: x2, y: y2 };
    }

    // Otherwise, calculate the coordinates of the closest point
    const closestX = x1 + t * dx;
    const closestY = y1 + t * dy;

    return { x: closestX, y: closestY };
  };

  const onLoad = (event) => {
    console.log('LOADING: clearing all objects');
    setObjects([]);
    const data = event.detail.data;

    let newObject;
    setDrawing(false);
    setDrawingShape(null);

    if (data.length === 0) return setObjects([]);

    for (var i = 0; i < data.length; i++) {
      console.log(data[i].type);
      let baseProps = {
        key: data[i].key,
        id: data[i].id,
        draggable: allowDragging,
        stageRef: stageRef,
        snapObjectToExistingObjects: snapObjectToExistingObjects,
        group: data[i].group,
        groupOptions: props.groupOptions,
        setDefaultGroup: props.setDefaultGroup,
        fontSize: data[i].fontSize,
        setObjects: setObjects,
        setSaveData: (e, preventHistoryIncrement) => props.setSaveData(e, true),
        addObjectToSaveData: props.addObjectToSaveData,
        updateObjectInSaveData: props.updateObjectInSaveData,
        delete: handleDeleteObject,
        setSelected: setSelected
      };

      switch (data[i].type) {
        case 'Fence':
          baseProps = { ...baseProps, points: data[i].points, feet: data[i].feet, inches: data[i].inches };
          newObject = <Fence {...baseProps}></Fence>;
          createNewObject(newObject);
          break;
        case 'Tear-Out':
          baseProps = { ...baseProps, points: data[i].points, feet: data[i].feet, inches: data[i].inches };
          newObject = <TearOutFence {...baseProps}></TearOutFence>;
          createNewObject(newObject);
          break;
        case 'Existing':
          baseProps = { ...baseProps, points: data[i].points, feet: data[i].feet, inches: data[i].inches };
          newObject = <ExistingFence {...baseProps}></ExistingFence>;
          createNewObject(newObject);
          break;
        case 'CircleWithTransformer':
          baseProps = { ...baseProps, x: data[i].x, y: data[i].y, radius: data[i].radius };
          newObject = <CircleWithTransformer {...baseProps} />;
          createNewObject(newObject);

          break;
        case 'RectWithTransformer':
          baseProps = {
            ...baseProps,
            x: data[i].x,
            y: data[i].y,
            width: data[i].width,
            height: data[i].height,
            scaleX: data[i].scaleX,
            scaleY: data[i].scaleY,
            rotation: data[i].rotation
          };
          newObject = <RectWithTransformer {...baseProps} />;
          createNewObject(newObject);
          break;
        case 'Gate':
          baseProps = { ...baseProps, points: data[i].points, feet: data[i].feet, inches: data[i].inches, snapGateToFence: snapGateToFence };
          newObject = <Gate {...baseProps}></Gate>;
          createNewObject(newObject);
          break;
        case 'DoubleGate':
          baseProps = { ...baseProps, points: data[i].points, feet: data[i].feet, inches: data[i].inches, snapGateToFence: snapGateToFence };
          newObject = <DoubleGate {...baseProps}></DoubleGate>;
          createNewObject(newObject);
          break;
        case 'Arrow':
          baseProps = { ...baseProps, points: data[i].points, x: data[i].x, y: data[i].y };
          newObject = <Arrow {...baseProps} />;
          createNewObject(newObject);

          break;
        case 'DraggableText':
          baseProps = { ...baseProps, x: data[i].x, y: data[i].y, text: data[i].text };
          newObject = <DraggableText {...baseProps} />;

          if (data[i].text === '') break;
          createNewObject(newObject);
          props.setCurrentTool('pan');
          break;
        case 'Pool':
          baseProps = {
            ...baseProps,
            x: data[i].x,
            y: data[i].y,
            width: data[i].width,
            height: data[i].height,
            scaleX: data[i].scaleX,
            scaleY: data[i].scaleY,
            rotation: data[i].rotation
          };
          newObject = <Pool {...baseProps} />;
          createNewObject(newObject);
          break;
        case 'Tree':
          baseProps = { ...baseProps, x: data[i].x, y: data[i].y, radius: data[i].radius };
          newObject = <Tree {...baseProps} />;
          createNewObject(newObject);
          break;
        case 'DraggableImage':
          console.log(data[i]);
          baseProps = {
            ...baseProps,
            x: data[i].x,
            y: data[i].y,
            url: data[i].url,
            // width: data[i].width,
            // height: data[i].height,
            scaleX: data[i].scaleX,
            scaleY: data[i].scaleY,
            rotation: data[i].rotation
          };
          newObject = <DraggableImage {...baseProps} />;
          createNewObject(newObject);
          break;
        default:
          break;
      }
    }

    //props.setCurrentTool('pan');
  };

  const haveIntersection = (r1, r2) => {
    return !(r2.x > r1.x + r1.width || r2.x + r2.width < r1.x || r2.y > r1.y + r1.height || r2.y + r2.height < r1.y);
  };
  // console.log('rendered draw space');

  const handleUndo = () => {
    if (historyStep === 0) {
      console.log('No more history to undo');
      return;
    }
    return dispatch({ type: 'UNDO' });
  };

  const handleRedo = () => {
    if (historyStep === history.length - 1) {
      console.log('No more history to redo');
      return;
    }
    return dispatch({ type: 'REDO' });
  };

  const handleDeleteObject = async (shapeId) => {
    console.log(objects);

    if (selected) {
      selected.current.destroy();
    }

    shapeId = selected?.current.attrs.id || shapeId;

    props.deleteObject(shapeId);

    props.setSaveData((prevState) => {
      let newState = { ...prevState };
      delete newState[shapeId];
      return newState;
    });

    //setObjects(objects.filter((e) => e.key !== shapeId));
  };

  const toggleGrid = () => {
    dispatch({
      type: 'SET_GRID',
      data: !gridVisible
    });
    setGridVisible((prevVisible) => !prevVisible);
  };

  const toggleLegend = () => {
    dispatch({
      type: 'SET_LEGEND',
      data: !legendVisible
    });
    setLegendVisible((prevVisible) => !prevVisible);
  };

  // console.log(objects);
  // console.log(history);
  // console.log(historyStep);
  // console.log(history[historyStep - 1]);
  // console.log(saveData);
  // console.log(history);

  return (
    <Box
      ref={boxRef}
      sx={{
        // width: '100%',
        // height: '100%'
        width: width,
        height: height
        //backgroundColor: '#F4F4F4'
      }}
    >
      <div className={` fixed top-[45%] flex flex-col z-[99999]`}>
        <Tooltip
          title="Toggle Legend"
          className="!z-[999999]"
          placement="right-end"
        >
          <IconButton
            onClick={toggleLegend}
            //disabled={history[historyStep - 1] === undefined}
            color="#212121"
          >
            {legendVisible ? <KeyOffIcon /> : <KeyIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Toggle Grid"
          className="!z-[999999]"
          placement="right-end"
        >
          <IconButton
            onClick={toggleGrid}
            //disabled={history[historyStep - 1] === undefined}
            color="#212121"
          >
            {gridVisible ? <GridOffIcon /> : <GridOnIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Undo"
          className="!z-[999999]"
          placement="right-end"
        >
          <IconButton
            onClick={handleUndo}
            disabled={history[historyStep - 1] === undefined}
            color="#212121"
          >
            <UndoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Redo"
          className="!z-[99999999999]"
          placement="right-end"
        >
          <IconButton
            onClick={handleRedo}
            disabled={history[historyStep + 1] === undefined}
            color="#212121"
          >
            <RedoIcon />
          </IconButton>
        </Tooltip>
        <IconButton
          onClick={handleDeleteObject}
          disabled={selected === null || selected === undefined}
          color="#212121"
        >
          <DeleteIcon />
        </IconButton>
      </div>

      <Stage
        width={width}
        height={height}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onContextMenu={onContextMenu}
        onClick={(e) => {
          const emptySpace = e.target === e.target.getStage();

          if (emptySpace) {
            setSelected(null);
          }
        }}
        onTap={(e) => {
          const emptySpace = e.target === e.target.getStage();

          if (emptySpace) {
            setSelected(null);
          }
        }}
        onWheel={onWheel}
        draggable={allowDragging & !isMobile}
        //draggable={true}
        ref={stageRef}
        //style={{ backgroundColor: 'red' }}
      >
        {/* Grid Layer */}
        {gridVisible && (
          <Layer>
            {/* Draw grid lines */}
            {Array.from({ length: (width * 1.5) / gridSize }, (_, i) => (
              <Line
                key={`verticalLine_${i}`}
                points={[i * gridSize, 0, i * gridSize, height * 1.5]}
                stroke="#ddd"
                strokeWidth={1}
              />
            ))}
            {Array.from({ length: (height * 1.5) / gridSize }, (_, i) => (
              <Line
                key={`horizontalLine_${i}`}
                points={[0, i * gridSize, width * 1.5, i * gridSize]}
                stroke="#ddd"
                strokeWidth={1}
              />
            ))}
          </Layer>
        )}
        <Layer ref={layerRef}>
          {objects.map((object) => {
            return object;
          })}
        </Layer>
        <Layer ref={drawingLayerRef}>{drawingShape}</Layer>
      </Stage>
      {legendVisible && <Legend {...props} />}
    </Box>
  );
};

export default KonvaDrawSpace;
