import React from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

function LoadDrawing({ drawing, loadDrawing }) {
  const iconStyle = {
    color: '#3F3F3F', // Replace with your desired color
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  };

  return (
    <IconButton
      onClick={() => loadDrawing(drawing)}
      style={iconStyle}
    >
      <EditIcon />
    </IconButton>
  );
}

export default LoadDrawing;
