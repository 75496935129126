import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth';

import { query, getDocs, collection, where, addDoc, updateDoc, getDoc, doc } from 'firebase/firestore';

import { auth, db } from './firebase';

import store from '../store/store';

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    let user = res.user;
    let userData;

    // Construct the query to check if the user exists in "users" collection
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('uid', '==', user.uid));

    // Fetch the documents that match the query
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length === 0) {
      // If user not found in "users" collection, add user data to Firestore
      const newUserDocRef = await addDoc(usersRef, {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
        organization: '' // Initialize organizationId as an empty string for now
      });

      // Determine the organization ID based on the email domain
      const userEmail = user.email;
      const userEmailDomain = userEmail.split('@')[1];
      console.log(userEmailDomain);

      if (!genericDomains.includes(userEmailDomain)) {
        // If the user's domain is not in the list of generic domains, create an organization
        const organizationId = await getOrganizationIdFromEmailDomain(userEmailDomain, userEmail);
        console.log(organizationId);
        user.organization = organizationId;
        // Update the user's document with the organization ID
        await updateDoc(newUserDocRef, {
          organization: organizationId
        });
      }

      const newUserSnapshot = await getDoc(newUserDocRef);
      userData = newUserSnapshot.data();
      console.log(userData);
    } else {
      // If the user already exists in "users" collection, check if they have an organizationId
      userData = querySnapshot.docs[0]?.data() || {};

      if (!userData.organization) {
        // If the user doesn't have an organizationId, create an organization and link it to the user
        const userEmail = user.email;
        const userEmailDomain = userEmail.split('@')[1];

        if (!genericDomains.includes(userEmailDomain)) {
          // If the user's domain is not in the list of generic domains, create an organization
          const organizationId = await getOrganizationIdFromEmailDomain(userEmailDomain, userEmail);
          console.log(organizationId);
          user.organization = organizationId;
          // Update the user's document with the organization ID
          await updateDoc(querySnapshot.docs[0].ref, {
            organization: organizationId
          });

          userData.organization = organizationId; // Update userData with the organizationId
        }
      }

      console.log(userData);
    }

    // Dispatch a 'LOGIN' action with the user data to your Redux store
    store.dispatch({
      type: 'LOGIN',
      data: {
        ...userData,
        photoURL: user.photoURL,
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        organization: user.organization
      }
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const getOrganizationIdFromEmailDomain = async (emailDomain, userEmail) => {
  try {
    const organizationsRef = collection(db, 'organizations');
    const q = query(organizationsRef, where('domain', '==', emailDomain));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // If the document exists, return the corresponding organization ID
      const organizationId = querySnapshot.docs[0].id;

      const organizationRef = doc(db, 'organizations', organizationId);

      const organizationData = querySnapshot.docs[0].data();

      // Check if "members" array is missing, and if so, set it with the user as the only member
      if (!organizationData.members) {
        await updateDoc(organizationRef, { members: [userEmail] });
      } else {
        // Update the organization's "members" array to include the new user if not already present
        if (!organizationData.members.includes(userEmail)) {
          const updatedMembers = [...organizationData.members, userEmail];
          await updateDoc(organizationRef, { members: updatedMembers });
        }
      }

      // Check if "owner" is missing, and if so, set the user as the owner
      if (!organizationData.owner) {
        await updateDoc(organizationRef, { owner: userEmail });
      }

      return organizationId;
    } else {
      // If the email domain is not found in the "organizations" collection, create a new organization and return its ID

      const organizationDocRef = await addDoc(organizationsRef, {
        domain: emailDomain,
        owner: userEmail,
        members: [userEmail]
      });
      console.log(organizationDocRef.id);
      return organizationDocRef.id;
    }
  } catch (error) {
    console.error('Error getting organization ID:', error);
    return null;
  }
};

const acceptInviteSignInWithGoogle = async (organizationId) => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    console.log(user);
    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
    console.log(q);
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      //await signOut(auth);
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
        organization: organizationId
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const login = async (email, password) => {
  try {
    const user = await signInWithEmailAndPassword(auth, email, password);
    return user;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const register = async (email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      authProvider: 'local',
      email: email
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Password reset link sent!');
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = async () => {
  store.dispatch({ type: 'LOGOUT' });
  await signOut(auth);
};

// const setLastSignIn = auth.user().onCreate((user) => {
//   // Set the last sign-in time as the current time.
//   const lastSignInTime = Math.floor(Date.now() / 1000);
//   return auth().setCustomUserClaims(user.uid, {
//       lastSignIn: lastSignInTime
//   });
// });

export { signInWithGoogle, acceptInviteSignInWithGoogle, login, register, sendPasswordReset, logout, auth };

const generateNewOrganizationId = () => {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base36 string
  const randomString = Math.random().toString(36).substr(2, 5); // Generate a random 5-character string

  return `${timestamp}-${randomString}`;
};

const genericDomains = [
  'gmail.com',
  'outlook.com',
  'yahoo.com',
  'aol.com',
  'hotmail.com',
  'icloud.com',
  'mail.com',
  'gmx.com',
  'protonmail.com',
  'zoho.com',
  'yandex.com',
  'live.com',
  'comcast.net',
  'cox.net',
  'me.com',
  'att.net',
  'verizon.net',
  'rocketmail.com',
  'sbcglobal.net',
  'earthlink.net',
  'mail.ru',
  'inbox.com',
  'gmx.net',
  'bellsouth.net',
  'optonline.net',
  'web.de',
  'rediffmail.com',
  't-online.de',
  'free.fr',
  'qq.com',
  '163.com',
  'sina.com',
  'naver.com',
  'hanmail.net',
  'daum.net',
  'rambler.ru',
  'onet.pl',
  'onet.eu',
  'onet.com',
  'onet.com.pl',
  'globo.com',
  'terra.com.br',
  'bol.com.br',
  'uol.com.br',
  'ig.com.br',
  'yahoo.com.br',
  'r7.com',
  'hotmail.com.br',
  'yahoo.co.uk',
  'btinternet.com',
  'virginmedia.com',
  'blueyonder.co.uk',
  'ntlworld.com',
  'talktalk.co.uk',
  'sky.com',
  'libero.it',
  'fastwebnet.it',
  'tin.it',
  'alice.it',
  'virgilio.it',
  'tiscali.it',
  'telecomitalia.it',
  'gmail.de',
  'gmx.de',
  't-online.de',
  'yahoo.de',
  'web.de',
  'aol.de',
  'arcor.de',
  'hotmail.de',
  't-online.com',
  'mac.com',
  'yahoo.fr',
  'hotmail.fr',
  'laposte.net',
  'wanadoo.fr',
  'orange.fr',
  'yahoo.co.jp',
  'hotmail.co.jp',
  'yahoo.co.in',
  'rediffmail.com',
  'yahoo.in',
  'indiatimes.com',
  'hotmail.co.in',
  'yahoo.com.au',
  'yahoo.com.sg',
  'yahoo.com.ph',
  'yahoo.com.my',
  'yahoo.com.vn',
  'yahoo.com.tw',
  'yahoo.co.id',
  'yahoo.com.ar',
  'yahoo.com.mx',
  'yahoo.ca',
  'yahoo.com.tr',
  'yahoo.com.co',
  'yahoo.com.pe',
  'yahoo.com.ve',
  'yahoo.com.ec',
  'yahoo.com.br',
  'yahoo.cl',
  'yahoo.com.co',
  'yahoo.com.sv',
  'yahoo.com.gt',
  'yahoo.com.ni',
  'yahoo.com.hn',
  'yahoo.com.pr',
  'yahoo.com.do',
  'yahoo.com.py',
  'yahoo.com.bo',
  'yahoo.com.uy',
  'yahoo.com.ar',
  'yahoo.com.cu',
  'yahoo.com.cw',
  'yahoo.com.tt',
  'yahoo.com.bz',
  'yahoo.com.ag',
  'yahoo.com.dm',
  'yahoo.com.vc',
  'yahoo.com.ky',
  'yahoo.com.jm',
  'yahoo.com.gi',
  'yahoo.com.mt',
  'yahoo.com.cy',
  'yahoo.com.gr',
  'yahoo.com.mk',
  'yahoo.com.ru',
  'yahoo.com.ua',
  'yahoo.com.kz',
  'yahoo.com.tm',
  'yahoo.com.uz',
  'yahoo.com.kg',
  'yahoo.com.tj',
  'yahoo.com.by',
  'yahoo.com.md',
  'yahoo.com.am',
  'yahoo.com.az',
  'yahoo.com.ge',
  'yahoo.com.ye',
  'yahoo.com.ps',
  'yahoo.com.sy',
  'yahoo.com.lb',
  'yahoo.com.jo',
  'yahoo.com.iq',
  'yahoo.com.sa',
  'yahoo.com.om',
  'yahoo.com.bh',
  'yahoo.com.qa',
  'yahoo.com.kw',
  'yahoo.com.ae',
  'yahoo.com.tr',
  'yahoo.com.ir',
  'yahoo.com.pk',
  'yahoo.com.af',
  'yahoo.com.np',
  'yahoo.com.bd',
  'yahoo.com.lk',
  'yahoo.com.mm',
  'yahoo.com.cn',
  'yahoo.com.hk',
  'yahoo.com.mo',
  'yahoo.com.tw',
  'yahoo.com.sg',
  'yahoo.com.my',
  'yahoo.com.ph',
  'yahoo.com.vn',
  'yahoo.com.id',
  'yahoo.com.kh',
  'yahoo.com.bn',
  'yahoo.com.au',
  'yahoo.com.fj',
  'yahoo.com.sb',
  'yahoo.com.vu',
  'yahoo.com.to',
  'yahoo.com.ws',
  'yahoo.com.ki',
  'yahoo.com.nr',
  'yahoo.com.tl',
  'yahoo.com.pg',
  'yahoo.com.as',
  'yahoo.com.ck',
  'yahoo.com.tv',
  'yahoo.com.wf',
  'yahoo.com.wm',
  'yahoo.com.ug',
  'yahoo.com.zm',
  'yahoo.com.na',
  'yahoo.com.zw',
  'yahoo.com.co',
  'yahoo.com.uy',
  'yahoo.com.br',
  'yahoo.com.cu',
  'yahoo.com.tt',
  'yahoo.com.bz',
  'yahoo.com.ag',
  'yahoo.com.dm',
  'yahoo.com.vc',
  'yahoo.com.ky',
  'yahoo.com.jm',
  'yahoo.com.gi',
  'yahoo.com.mt',
  'yahoo.com.cy',
  'yahoo.com.gr',
  'yahoo.com.mk',
  'yahoo.com.ru',
  'yahoo.com.ua',
  'yahoo.com.kz',
  'yahoo.com.tm',
  'yahoo.com.uz',
  'yahoo.com.kg',
  'yahoo.com.tj',
  'yahoo.com.by',
  'yahoo.com.md',
  'yahoo.com.am',
  'yahoo.com.az',
  'yahoo.com.ge',
  'yahoo.com.ye',
  'yahoo.com.ps',
  'yahoo.com.sy',
  'yahoo.com.lb',
  'yahoo.com.jo',
  'yahoo.com.iq',
  'yahoo.com.sa',
  'yahoo.com.om',
  'yahoo.com.bh',
  'yahoo.com.qa',
  'yahoo.com.kw',
  'yahoo.com.ae',
  'yahoo.com.tr',
  'yahoo.com.ir',
  'yahoo.com.pk',
  'yahoo.com.af',
  'yahoo.com.np',
  'yahoo.com.bd',
  'yahoo.com.lk',
  'yahoo.com.mm',
  'yahoo.com.cn',
  'yahoo.com.hk',
  'yahoo.com.mo',
  'yahoo.com.tw',
  'yahoo.com.sg',
  'yahoo.com.my',
  'yahoo.com.ph',
  'yahoo.com.vn',
  'yahoo.com.id',
  'yahoo.com.kh',
  'yahoo.com.bn',
  'yahoo.com.au',
  'yahoo.com.fj',
  'yahoo.com.sb',
  'yahoo.com.vu',
  'yahoo.com.to',
  'yahoo.com.ws',
  'yahoo.com.ki',
  'yahoo.com.nr',
  'yahoo.com.tl',
  'yahoo.com.pg',
  'yahoo.com.as',
  'yahoo.com.ck',
  'yahoo.com.tv',
  'yahoo.com.wf',
  'yahoo.com.wm',
  'yahoo.com.ug',
  'yahoo.com.zm',
  'yahoo.com.na',
  'yahoo.com.zw',
  'yahoo.com.co',
  'yahoo.com.uy',
  'yahoo.com.br',
  'yahoo.com.cu',
  'yahoo.com.tt',
  'yahoo.com.bz',
  'yahoo.com.ag',
  'yahoo.com.dm',
  'yahoo.com.vc',
  'yahoo.com.ky',
  'yahoo.com.jm',
  'yahoo.com.gi',
  'yahoo.com.mt',
  'yahoo.com.cy',
  'yahoo.com.gr',
  'yahoo.com.mk',
  'yahoo.com.ru',
  'yahoo.com.ua',
  'yahoo.com.kz',
  'yahoo.com.tm',
  'yahoo.com.uz',
  'yahoo.com.kg',
  'yahoo.com.tj',
  'yahoo.com.by',
  'yahoo.com.md',
  'yahoo.com.am',
  'yahoo.com.az',
  'yahoo.com.ge',
  'yahoo.com.ye',
  'yahoo.com.ps',
  'yahoo.com.sy',
  'yahoo.com.lb',
  'yahoo.com.jo',
  'yahoo.com.iq',
  'yahoo.com.sa',
  'yahoo.com.om',
  'yahoo.com.bh',
  'yahoo.com.qa',
  'yahoo.com.kw',
  'yahoo.com.ae',
  'yahoo.com.tr',
  'yahoo.com.ir',
  'yahoo.com.pk',
  'yahoo.com.af',
  'yahoo.com.np',
  'yahoo.com.bd',
  'yahoo.com.lk',
  'yahoo.com.mm',
  'yahoo.com.cn',
  'yahoo.com.hk',
  'yahoo.com.mo',
  'yahoo.com.tw',
  'yahoo.com.sg',
  'yahoo.com.my',
  'yahoo.com.ph',
  'yahoo.com.vn',
  'yahoo.com.id',
  'yahoo.com.kh',
  'yahoo.com.bn',
  'yahoo.com.au',
  'yahoo.com.fj',
  'yahoo.com.sb',
  'yahoo.com.vu',
  'yahoo.com.to',
  'yahoo.com.ws',
  'yahoo.com.ki',
  'yahoo.com.nr',
  'yahoo.com.tl',
  'yahoo.com.pg',
  'yahoo.com.as',
  'yahoo.com.ck',
  'yahoo.com.tv',
  'yahoo.com.wf',
  'yahoo.com.wm',
  'yahoo.com.ug',
  'yahoo.com.zm',
  'yahoo.com.na',
  'yahoo.com.zw'
];
