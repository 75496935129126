import { updateDoc, arrayUnion, getDoc, doc, collection, addDoc, deleteDoc, getDocs, setDoc } from 'firebase/firestore';
import { db } from './firebase';
const { notify } = require('../utils/notification');

export const updateDrawing = async (organizationId, data) => {
  console.log(data);

  try {
    const organizationDocRef = doc(db, 'organizations', organizationId, 'drawings', data.id);
    let newData = data;

    await getDoc(organizationDocRef)
      .then(async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const oldData = docSnapshot.data();
          // Process the retrieved document data
          console.log(oldData);
          await updateDoc(organizationDocRef, {
            saveDataObject: newData.saveDataObject,
            lastUpdated: newData.lastUpdated,
            thumbnail: newData.thumbnail
          });
        } else {
          // Document does not exist
          console.log('Document does not exist');
        }
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error fetching document:', error);
      });
  } catch (error) {
    console.log(error.message);
    notify('danger', 'Error', error.message);
  }
  // try {
  //   // Fetch the drawings collection
  //   const drawingsSnapshot = await getDocs(drawingsCollectionRef);

  //   // Find the drawing with the specified ID
  //   let drawingDocRef;
  //   drawingsSnapshot.forEach((doc) => {
  //     const drawingData = doc.data();
  //     if (drawingData.id === data.id) {
  //       drawingDocRef = doc.ref;
  //     }
  //   });
  //   console.log(drawingDocRef);
  //   // If the drawing is found, update it
  //   if (drawingDocRef) {
  //     await updateDoc(drawingDocRef, {
  //       saveDataObject: data.saveDataObject,
  //       lastUpdated: data.lastUpdated,
  //       thumbnail: data.thumbnail
  //     });
  //     notify('success', 'Success', 'Drawing successfully saved');
  //   } else {
  //     notify('danger', 'Error', 'Drawing not found');
  //   }
  // } catch (error) {
  //   console.log(error.message);
  //   notify('danger', 'Error', error.message);
  // }
};

export const addNewDrawing = async (organizationId, drawingData) => {
  try {
    const newDrawingRef = doc(db, 'organizations', organizationId, 'drawings', drawingData.id);
    await setDoc(newDrawingRef, { ...drawingData, id: drawingData.id });

    // // Update the user document with the new drawing reference using arrayUnion
    // await updateDoc(userDocReference, {
    //   drawings: arrayUnion(drawingsCollectionRef)
    // });

    return notify('success', 'Success', 'New drawing successfully saved');
  } catch (error) {
    console.log(error.message);
    return notify('danger', 'Error', error.message);
  }
};

export const deleteDrawing = async (drawingsCollectionRef, id) => {
  try {
    // Fetch the drawings collection
    const drawingsSnapshot = await getDocs(drawingsCollectionRef);

    // Find the drawing with the specified ID
    let drawingDocRef;
    drawingsSnapshot.forEach((doc) => {
      const drawingData = doc.data();
      if (drawingData.id === id) {
        drawingDocRef = doc.ref;
      }
    });

    // If the drawing is found, delete it
    if (drawingDocRef) {
      await deleteDoc(drawingDocRef);
      notify('success', 'Success', 'Drawing successfully deleted');
    } else {
      notify('danger', 'Error', 'Drawing not found');
    }
  } catch (error) {
    notify('danger', 'Error', error.message);
  }
};

export const getOrganizationDrawings = async (organizationDocReference) => {
  try {
    const drawingsCollectionRef = collection(organizationDocReference, 'drawings');
    const drawingsSnapshot = await getDocs(drawingsCollectionRef);
    const drawingsData = drawingsSnapshot.docs.map((doc) => doc.data());
    console.log(drawingsData);
    return drawingsData;
  } catch (error) {
    console.error('Error getting organization drawings:', error);
    return [];
  }
};

// export const getDrawings = async (userDocReference, data) => {
//   await updateDoc(userDocReference, data);
//   return console.log('user drawing added');
// };
