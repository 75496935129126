import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { register } from '../../api/auth';
import { notify } from '../../utils/notification';

function Register() {
  //const [registerForm, setRegisterForm] = useState({email: null, firstPassword: null, secondPassword: null});
  const emailRef = React.useRef(null);
  const firstPasswordRef = React.useRef(null);
  const secondPasswordRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onEnterKey = async (event) => {
    if (event.charCode === 13) {
      let email = emailRef.current.value;
      let firstPassword = firstPasswordRef.current.value;
      let secondPassword = secondPasswordRef.current.value;

      try {
        if (!email || !firstPassword || !secondPassword) throw 'Please fill out all fields.';
        if (!email.includes('@') || !email.includes('.com')) throw 'Please insert a valid email.';
        if (firstPassword !== secondPassword) throw 'Passwords do not match.';
        if (!firstPassword || !secondPassword) throw 'Please fill out all fields.';
      } catch (error) {
        console.log(error);
        return notify('warning', 'Missing Fields', error.toString());
      }
      console.log(email, firstPassword);
      const response = await register(email, firstPassword);

      setLoading(false);

      if (response.error) return notify('danger', 'Error', JSON.stringify(response.error));

      notify('success', 'Email sent', 'Please check your inbox to confirm registration.');

      return setTimeout(() => {
        return (window.location = '/login');
      }, 5000);
    }
  };

  return (
    <div className="relative font-sans text-primaryText overflow-hidden w-full h-full">
      <div className="m-auto w-3/4 max-w-sm h-2/5 rounded-md px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
        <div className="grid grid-cols-1 space-y-5 w-full ml-auto mr-auto m-auto px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <img
            className="inline ml-auto mr-auto w-24"
            src={require('../../config.json').logo}
          />
          <input
            ref={emailRef}
            placeholder="Email"
            spellcheck="false"
            id="newIp"
            onKeyPress={onEnterKey}
            autoFocus
            className="pt-2 rounded-md text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full"
            //onChange={(e)=> setEmail(emailRef.current.value)}
          />
          <input
            ref={firstPasswordRef}
            type="password"
            placeholder="Password"
            id="newIp"
            onKeyPress={onEnterKey}
            className="rounded-md text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full"
            //onChange={(e)=> setPassword(passwordRef.current.value)}
          />
          <input
            ref={secondPasswordRef}
            type="password"
            placeholder="Verify password"
            id="newIp"
            onKeyPress={onEnterKey}
            className="rounded-md text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full"
            //onChange={(e)=> setPassword(passwordRef.current.value)}
          />

          <button
            onClick={() => onEnterKey({ charCode: 13 })}
            className="font-medium p-1 bg-header text-white shadow-md rounded-md text-lg focus:outline-none outline-none hover:opacity-70 transition duration-300"
          >
            Register
          </button>
          <div className="grid-rows-1 flex flex-wrap content-evenly">
            <button
              onClick={() => navigate('/login')}
              className="focus:outline-none outline-none hover:opacity-70 transition duration-300 mr-auto ml-auto"
            >
              <text className="buttonText text-xs opacity-60">Back to login</text>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
