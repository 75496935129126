import { createTheme } from '@mui/material/styles';

// reference: https://atlantis.getjobber.com/?path=/docs/design-colors--page

// Create a theme instance.

const theme = {
  palette: {
    typography: {
      heading: {
        primary: '#3F3F3F'
      },
      text: {
        primary: '#424E56',
        secondary: '#657884'
      },
      reverse: {
        primary: '#FFFFFF',
        secondary: '#C1C9CE'
      }
    },
    interactions: {
      interactive: { default: '#7DB00E', hover: '#517209' },
      subtle: { default: '#657884', hover: '#424E56' },
      destructive: { default: '#C94221', hover: '#801900' },
      disabled: { default: '#B5B5B5', secondary: '#E1E1E1' },
      focus: { default: '#E7D557', hover: '' }
    },
    status: {
      critical: { default: '#C94221', surface: '#FFE2DB', onSurface: '#801900' },
      warning: { default: '#DDC30F', surface: '#FAF6DB', onSurface: '#907F0A' },
      success: { default: '#7DB00E', surface: '#ECF3DB', onSurface: '#517209' },
      informative: { default: '#3CA2E0', surface: '#E2F1FA', onSurface: '#276992' },
      inactive: { default: '#4D6974', surface: '#D9DFE1', onSurface: '#011B25' }
    },
    surfaces: {
      surface: { default: '#FFFFFF', hover: '#FAF6DB', active: '#ECF3DB' },
      background: '#F4F4F4',
      reverse: '#012939'
    },
    overlay: {
      default: '#ADADAD',
      dimmed: '#FFFFFF'
    },
    borders: {
      border: '#E1E1E1',
      section: '#012939'
    },
    brand: {
      brand: '#3F3F3F',
      highlight: '#A0D72A'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&.Mui-selected': {
            backgroundColor: theme.palette.overlay.default,
            color: theme.palette.typography.reverse.primary
          },
          '&.Mui-selected:hover': {
            backgroundColor: theme.palette.overlay.default,
            color: theme.palette.typography.reverse.primary
          }
        })
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.brand.brand,
          color: theme.palette.typography.reverse.primary,
          '&:hover': {
            backgroundColor: theme.palette.brand.brand,
            opacity: 0.9
          }
        })
      }
    }
    // MuiBadge: {
    //   styleOverrides: {
    //     root: ({ ownerState, theme }) => ({
    //       backgroundColor: 'black',
    //       color: theme.palette.typography.reverse.primary
    //     })
    //   }
    // }
  },

  typography: {
    fontFamily: ['Montserrat, sans-serif']
  }
};

export default theme;
