import { useRef, useEffect, useState, forwardRef } from 'react';
import { Group, Line, Circle, Text, Arc } from 'react-konva';
import { Html } from 'react-konva-utils';
import {
  TextField,
  InputAdornment,
  Box,
  FormControl,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  useMediaQuery,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Grid,
  ListItemText,
  ListItemButton,
  Divider,
  Typography,
  AppBar,
  Toolbar,
  List,
  Slide
} from '@mui/material';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import doorSwing90Icon from '../../../assets/door-swing-90.svg'; // Import your icon images
import doorSwing90MirroredIcon from '../../../assets/door-swing-90-mirrored.svg';

export default function DoubleGate(props) {
  const [selected, setSelected] = useState(false);
  const [isDraggingAllowed, setIsDraggingAllowed] = useState(false);
  const [feet, setFeet] = useState(props.feet || '');
  const [inches, setInches] = useState(props.inches || '');
  const [fontSize, setFontSize] = useState(props.fontSize || 20);
  const [group, setGroup] = useState(props.group || 'A');
  const [angle, setAngle] = useState(-90);
  const [angle2, setAngle2] = useState(-90);

  const [gateAngle, setGateAngle] = useState(180);
  const [gateAngle2, setGateAngle2] = useState(180);
  const [innerRadius, setInnerRadius] = useState(10);

  const [text, setText] = useState(null);
  const [color, setColor] = useState(props.stroke || '#4D4D22');
  const stageRef = props.stageRef;
  const snapGateToFence = props.snapGateToFence;
  const points = props.points;

  const lineRef = useRef(null);
  const arc1Ref = useRef(null);
  const arc2Ref = useRef(null);
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const anchorRef3 = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    document.addEventListener('draggingAllowed', onDraggingAllowed);
    document.addEventListener('clearSelections', onClearSelections);
    //console.log(window.document.hasFocus());
    return () => {
      document.removeEventListener('draggingAllowed', onDraggingAllowed);
      document.removeEventListener('clearSelections', onClearSelections);
    };
  }, []);

  useEffect(() => {
    let anchorPosition1 = snapGateToFence(anchorRef1.current);

    lineRef.current.setAttrs({ stroke: anchorPosition1.color || '#4D4D22' });
    arc1Ref.current.setAttrs({ stroke: anchorPosition1.color || '#4D4D22' });
    arc2Ref.current.setAttrs({ stroke: anchorPosition1.color || '#4D4D22' });
    textRef.current.setAttrs({ fill: anchorPosition1.color || '#4D4D22' });
  }, []);

  useEffect(() => {
    save();
    updateText();
  }, [inches, feet, group, fontSize]);

  useEffect(() => {
    let boundingRect = textRef.current.getSelfRect();

    textRef.current.setAttrs({
      offsetX: boundingRect.width / 2,
      offsetY: boundingRect.height / 2 + 20
    });
  }, [text]);

  useEffect(() => {
    setInnerRadius(
      Math.sqrt((anchorRef2.current?.attrs.x - anchorRef1.current?.attrs.x) ** 2 + (anchorRef2.current?.attrs.y - anchorRef1.current?.attrs.y) ** 2)
    );
    //setAngle(Math.atan2(points[3] - points[1], points[2] - points[0]));
    const originalAngle = Math.atan2(points[3] - points[1], points[2] - points[0]);
    const oppositeClockwise = originalAngle + Math.PI;
    const oppositeCounterclockwise = originalAngle - Math.PI;

    setAngle(originalAngle);
    setAngle2(oppositeCounterclockwise);
  }, [points]);

  const updateText = () => {
    let points = lineRef.current.attrs.points;

    let rotation = getAngle(points[0], points[1], points[2], points[3]);
    let x = (points[0] + points[2]) / 2;
    let y = (points[1] + points[3]) / 2;

    textRef.current.setAttrs({
      x: x,
      y: y,
      rotation: rotation
    });

    let text = [];

    if (feet !== '' && feet > 0) {
      text.push(`${feet}' DG`);
    }
    if (inches !== '' && inches > 0) {
      text.push(`${inches}''`);
    }
    if (text.length > 0) {
      text = text.join(' ');
    } else {
      text = null;
    }

    setText(text);
    let boundingRect = textRef.current.getSelfRect();

    textRef.current.setAttrs({
      offsetX: boundingRect.width / 2,
      offsetY: boundingRect.height / 2 + 20
    });
  };

  const onDraggingAllowed = (e) => {
    setIsDraggingAllowed(e.detail.state);
  };

  const onDragEndAnchors = (e) => {
    save();
  };

  const onDragMoveAnchors = (e) => {
    let x1, y1, x2, y2;

    if (e.target === anchorRef1.current) {
      let anchorPosition1 = snapGateToFence(anchorRef1.current);

      //let anchorPosition1 = anchorRef1.current.attrs;
      x1 = anchorPosition1.x;
      y1 = anchorPosition1.y;
      x2 = anchorRef3.current.attrs.x;
      y2 = anchorRef3.current.attrs.y;

      anchorRef1.current.setAttrs({
        x: x1,
        y: y1
      });

      lineRef.current.setAttrs({ stroke: anchorPosition1.color || '#4D4D22' });
      arc1Ref.current.setAttrs({ stroke: anchorPosition1.color || '#4D4D22' });
      arc2Ref.current.setAttrs({ stroke: anchorPosition1.color || '#4D4D22' });
    } else if (e.target === anchorRef3.current) {
      let anchorPosition3 = snapGateToFence(anchorRef3.current);
      //let anchorPosition3 = anchorRef2.current.attrs;
      x1 = anchorRef1.current.attrs.x;
      y1 = anchorRef1.current.attrs.y;
      x2 = anchorPosition3.x;
      y2 = anchorPosition3.y;

      anchorRef3.current.setAttrs({
        x: x2,
        y: y2
      });

      lineRef.current.setAttrs({ stroke: anchorPosition3.color || '#4D4D22' });
      arc1Ref.current.setAttrs({ stroke: anchorPosition3.color || '#4D4D22' });
      arc2Ref.current.setAttrs({ stroke: anchorPosition3.color || '#4D4D22' });
    }

    const points = [x1, y1, x2, y2];

    lineRef.current.setAttrs({
      points: points
    });

    anchorRef2.current.setAttrs({
      x: (points[0] + points[2]) / 2,
      y: (points[1] + points[3]) / 2
    });

    setInnerRadius(
      Math.sqrt((anchorRef2.current?.attrs.x - anchorRef1.current?.attrs.x) ** 2 + (anchorRef2.current?.attrs.y - anchorRef1.current?.attrs.y) ** 2)
    );

    const angle = Math.atan2(points[3] - points[1], points[2] - points[0]);
    const oppositeClockwise = angle + Math.PI;
    const oppositeCounterclockwise = angle - Math.PI;

    let rotation = angle * (180 / Math.PI) - gateAngle;
    let rotation2 = oppositeClockwise * (180 / Math.PI) - gateAngle;

    arc1Ref.current.setAttrs({
      x: (x1 + x2) / 2 + Math.cos(angle) * innerRadius,
      y: (y1 + y2) / 2 + Math.sin(angle) * innerRadius,
      rotation: rotation
    });

    arc2Ref.current.setAttrs({
      x: (x1 + x2) / 2 + Math.cos(oppositeClockwise) * innerRadius,
      y: (y1 + y2) / 2 + Math.sin(oppositeClockwise) * innerRadius,
      rotation: rotation2
    });

    updateText();
  };

  const onClearSelections = () => {
    setSelected(false);
  };

  const onContextMenu = (e) => {
    e.evt.preventDefault();
    const closePopoversEvent = new CustomEvent('clearSelections', {
      detail: { target: e.target }
    });
    document.dispatchEvent(closePopoversEvent);

    if (e.target === stageRef.current) {
      return;
    }

    setSelected(true);

    let objects = getLineGroups();

    objects.forEach((group) => {
      if (group.children[0].attrs.id !== lineRef.current.attrs.id) {
        group.opacity(0.2);
      } else {
        group.opacity(1);
      }
    });

    if (e.target.constructor.name === 'Arc' || 'Circle' || 'Text') return;
    scrollToLine(e.target.attrs.points);

    //lineRef.current.opacity(1);
  };

  const onClickDelete = () => {
    const shapeId = lineRef.current.id();
    props.delete(props.id);

    props.setSaveData((prevState) => {
      let newState = { ...prevState };
      delete newState[shapeId];
      return newState;
    });
    lineRef.current.destroy();
    anchorRef1.current.destroy();
    anchorRef2.current.destroy();
    anchorRef3.current.destroy();
    arc1Ref.current.destroy();
    arc2Ref.current.destroy();
    setSelected(false);

    let objects = getLineGroups();

    objects.forEach((line) => {
      line.opacity(1);
    });
  };

  const onCloseDialog = () => {
    setSelected(false);

    let objects = getLineGroups();

    objects.forEach((line) => {
      line.opacity(1);
    });
  };

  const onPreviousObject = () => {
    setSelected(false);
    let objects = getLineGroups();

    let objectToSelect = null;
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].attrs.id === props.id) {
        if (i === 0) {
          objectToSelect = objects[objects.length - 1];
        } else {
          objectToSelect = objects[i - 1];
        }
      }
    }

    if (objectToSelect !== null) {
      scrollToLine(objectToSelect.children[0].attrs.points);
      objectToSelect.children[0].dispatchEvent(new CustomEvent('contextmenu'));
    }
  };

  const onNextObject = () => {
    setSelected(false);
    //lineRef.current.opacity(0.3);

    let objects = getLineGroups();
    console.log(objects);
    let objectToSelect = null;
    for (let i = 0; i < objects.length; i++) {
      // object matches current line
      if (objects[i].attrs.id === props.id) {
        // if an object exists
        if (i === objects.length - 1) {
          objectToSelect = objects[0];
        }
        // if no object exists
        else {
          objectToSelect = objects[i + 1];
        }
      }
    }

    if (objectToSelect !== null) {
      scrollToLine(objectToSelect.children[0].attrs.points);
      objectToSelect.children[0].dispatchEvent(new CustomEvent('contextmenu'));
      // objectToSelect.children[0].opacity(1);
    }
  };

  const getAngle = (x1, y1, x2, y2) => {
    var dy = y2 - y1;
    var dx = x2 - x1;
    var theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    if (theta <= -90) {
      theta = 180 + theta;
    } else if (theta >= 90) {
      theta = theta - 180;
    }
    return theta;
  };

  const save = () => {
    if (props.setSaveData) {
      props.setSaveData({
        [props.id]: {
          id: props.id,
          key: props.id,
          type: 'DoubleGate',
          points: lineRef.current.attrs.points,
          inches: inches,
          feet: feet,
          group: group,
          fontSize: fontSize,
          textPoints: { x: textRef.current.attrs.x, y: textRef.current.attrs.y }
        }
      });
    }
  };

  const onFeetChange = (newValue) => {
    // Remove non-numeric characters using a regular expression
    newValue = newValue.replace(/\D/g, '');

    // Convert the string value to a number
    newValue = parseInt(newValue);

    // Check if the value is negative or NaN (e.g., if the input was empty)
    if (isNaN(newValue) || newValue < 0) {
      // Set the value to 0 if it's negative or NaN
      newValue = '';
    }

    // Update the state with the filtered and validated value
    setFeet(newValue);
  };

  const onInchesChange = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/\D/g, '');

    if (newValue < 0) {
      setInches(0);
    } else if (newValue > 12) {
      setInches(12);
    } else {
      setInches(newValue);
    }
  };

  const getLineGroups = () => {
    let objects = stageRef.current.children[1].children;

    objects = objects.filter((object) => object.getClassName() === 'Group' && object.attrs.scrollable);

    return objects;
  };

  const scrollToLine = (points) => {
    // Calculate the centroid of the shape using the points
    const centerX = (points[0] + points[2]) / 2;
    const centerY = (points[1] + points[3]) / 2;

    // Get the dimensions of the viewport
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate the position to center the shape on the stage
    const stageCenterX = viewportWidth / 2 + window.scrollX;
    const stageCenterY = viewportHeight / 2 + window.scrollY;
    const offsetX = stageCenterX - centerX;
    const offsetY = stageCenterY - centerY;

    // Update the position of the stage
    stageRef.current.position({ x: offsetX, y: offsetY });
    stageRef.current.batchDraw();
  };

  return (
    <Group
      key={props.id}
      type={'DoubleGate'}
      id={props.id}
      scrollable={true}
    >
      <Line
        {...props}
        ref={lineRef}
        hitStrokeWidth={5}
        onTap={onContextMenu}
        onContextMenu={onContextMenu}
        draggable={false}
        points={points}
        stroke={color}
        strokeWidth={5}
        type={'DoubleGate'}
        textColor={color}
      />
      <Arc
        ref={arc1Ref}
        onTap={onContextMenu}
        onContextMenu={onContextMenu}
        innerRadius={Math.sqrt(
          (anchorRef2.current?.attrs.x - anchorRef1.current?.attrs.x) ** 2 + (anchorRef2.current?.attrs.y - anchorRef1.current?.attrs.y) ** 2
        )}
        stroke={color}
        strokeWidth={5}
        angle={-90}
        rotation={angle * (gateAngle / Math.PI) - gateAngle}
        //rotation={rotation}
        x={(props.points[0] + props.points[2]) / 2 + Math.cos(angle) * innerRadius}
        y={(props.points[1] + props.points[3]) / 2 + Math.sin(angle) * innerRadius}
        clockwise={true}
      />
      <Arc
        ref={arc2Ref}
        onTap={onContextMenu}
        onContextMenu={onContextMenu}
        innerRadius={Math.sqrt(
          (anchorRef2.current?.attrs.x - anchorRef1.current?.attrs.x) ** 2 + (anchorRef2.current?.attrs.y - anchorRef1.current?.attrs.y) ** 2
        )}
        stroke={color}
        strokeWidth={5}
        angle={90}
        rotation={angle2 * (gateAngle / Math.PI) - gateAngle}
        //rotation={rotation}
        x={(props.points[0] + props.points[2]) / 2 + Math.cos(angle2) * innerRadius}
        y={(props.points[1] + props.points[3]) / 2 + Math.sin(angle2) * innerRadius}
        clockwise={false}
      />

      {/* Anchors and Text */}

      <Circle
        x={points[0]}
        y={points[1]}
        radius={4}
        fill={'#FFFFFF'}
        stroke={'#D54135'}
        strokeWidth={4}
        fillAfterStrokeEnabled={true}
        draggable={isDraggingAllowed}
        onDragMove={onDragMoveAnchors}
        onDragEnd={onDragEndAnchors}
        ref={anchorRef1}
        type={'dragHandle'}
        hitStrokeWidth={5}
        onTap={onContextMenu}
        onContextMenu={onContextMenu}
      />
      <Circle
        x={(props.points[0] + props.points[2]) / 2}
        y={(props.points[1] + props.points[3]) / 2}
        radius={4}
        fill={'#FFFFFF'}
        fillAfterStrokeEnabled={true}
        stroke={'#D54135'}
        strokeWidth={4}
        //draggable={isDraggingAllowed}
        ref={anchorRef2}
      />
      <Circle
        x={points[2]}
        y={points[3]}
        radius={4}
        fill={'#FFFFFF'}
        stroke={'#D54135'}
        strokeWidth={4}
        fillAfterStrokeEnabled={true}
        draggable={isDraggingAllowed}
        onDragMove={onDragMoveAnchors}
        onDragEnd={onDragEndAnchors}
        ref={anchorRef3}
        type={'dragHandle'}
        hitStrokeWidth={5}
        onTap={onContextMenu}
        onContextMenu={onContextMenu}
      />
      <Text
        text={text}
        x={(points[0] + points[2]) / 2}
        y={(points[1] + points[3]) / 2}
        draggable={true}
        ref={textRef}
        rotation={getAngle(points[0], points[1], points[2], points[3])}
        visible={text != null}
        padding={3}
        fill={color}
        fontStyle={'bold'}
        fontSize={fontSize}
        hitStrokeWidth={5}
        onDragEnd={(e) => save()}
        manuallyMoved={false}
        onContextMenu={onContextMenu}
      />
      <Html>
        <Dialog
          id={`fence-line-menu-${props.id}`}
          open={selected}
          onClose={onCloseDialog}
          TransitionComponent={Transition}
          maxWidth={'lg'}
          fullWidth={true}
          className="!bg-transparent !bg-none"
          PaperProps={{ elevation: 0 }}
          sx={{
            //You can copy the code below in your theme

            '& .MuiBackdrop-root': {
              backgroundColor: 'transparent' // Try to remove this to see the result
            }
          }}
        >
          <IconButton
            size="large"
            aria-label="Previous Object"
            onClick={onPreviousObject}
            className="fixed left-12 bg-white !z-[9999999] shadow-md"
            color="white"
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            size="large"
            aria-label="Next Object"
            onClick={onNextObject}
            className="fixed right-12 bg-white !z-[9999999] shadow-md"
          >
            <NavigateNextIcon />
          </IconButton>

          <AppBar
            sx={{ position: 'fixed', top: 'auto', bottom: 0, opacity: 1, backgroundColor: '#FFFFFF', height: '100px' }}
            className=" justify-center"
          >
            <Toolbar>
              {/* Edit: {props.type} */}

              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={3}
                >
                  <TextField
                    id="feet"
                    label="Feet"
                    autoComplete="off"
                    value={feet}
                    onChange={(e) => {
                      // Remove non-numeric characters using a regular expression
                      const numericValue = e.target.value.replace(/\D/g, '');
                      onFeetChange(numericValue); // Update the state with the filtered value
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onNextObject();
                      }
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">ft</InputAdornment>,
                      inputProps: {
                        min: 0,
                        autoFocus: true,
                        pattern: '[0-9]*',
                        type: 'number',
                        inputMode: 'numeric'
                        //inputmode: 'numeric'
                      },
                      pattern: '[0-9]*',
                      inputMode: 'numeric',
                      type: 'number'
                    }}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                >
                  <TextField
                    id="inches"
                    label="Inches"
                    value={inches}
                    onChange={(e) => onInchesChange(e)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">in</InputAdornment>,
                      inputProps: {
                        min: 0,
                        max: 12
                      },
                      inputMode: 'numeric'
                    }}
                    margin="normal"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <DialogActions className="flex-auto">
                <Button
                  color="error"
                  id="delete-button"
                  onClick={onClickDelete}
                >
                  Delete
                </Button>
                <Button
                  id="done"
                  onClick={onCloseDialog}
                >
                  Done
                </Button>
              </DialogActions>
            </Toolbar>
          </AppBar>
        </Dialog>
      </Html>
    </Group>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});
