const axios = require('axios');
const { notify } = require('../utils/notification');

async function uploadImageToCloudflare(blob) {
  //var base64result = blob.split(',')[1];
  const accountId = '389c3e215927dc97de2e4cd8f83f13e3';
  const apiToken = 'zn4baPvMQIZAENiz0kpiOfVRdtIOGM90JKUV5mTQ';
  const formData = new FormData();
  console.log(blob);
  formData.append('file', blob);
  console.log(formData);
  const response = await axios({
    method: 'post',
    url: `https://delicate-mud-81d7.nanosolutions.workers.dev/?https://api.cloudflare.com/client/v4/accounts/${accountId}/images/v1`,
    headers: {
      Authorization: `Bearer ${apiToken}`
    },
    data: formData
  })
    .then((response) => {
      console.log(response);
      return response.data.result;
      // handle response
    })
    .catch((error) => {
      console.log(error);
      // handle error
    });
  return response;
  //
}

export { uploadImageToCloudflare };
