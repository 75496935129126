import React, { useEffect } from 'react';

import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { db, auth, onAuthStateChanged } from '../api/firebase'; // Path to your firebase.js file
import { doc, getDocs, collection, where, query } from 'firebase/firestore'; // Import Firestore methods
import { useAuthState } from 'react-firebase-hooks/auth';
import { getUser, getUsersOrganization } from '../api/user';

const ProtectedRoute = ({ redirectPath = '/login', children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading] = useAuthState(auth);
  const user = useSelector((state) => state.auth.user);

  useEffect(async () => {
    if (loading && !user) return;

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && !loading) {
        // console.log(user);
        dispatch({ type: 'SET_LOADING', data: true });
        const userRef = await getDocs(query(collection(db, 'users'), where('uid', '==', user.uid))).then((querySnapshot) => {
          return querySnapshot.docs[0].ref;
        });
        const userData = await getUser(userRef);

        dispatch({
          type: 'SET_USER',
          data: { ...userData, photoURL: user.photoURL, uid: user.uid, email: user.email, displayName: user.displayName, ref: userRef }
        });

        const organizationData = await getUsersOrganization(userRef);
        // console.log(organizationData);
        dispatch({ type: 'SET_ORGANIZATION', data: organizationData });
        dispatch({ type: 'SET_LOADING', data: false });

        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            //console.log(idTokenResult);
            const lastSignIn = idTokenResult.claims.auth_time;
            const oneWeekAgo = Date.now() / 1000 - 7 * 24 * 60 * 60;

            if (lastSignIn < oneWeekAgo) {
              // Force sign out or re-authentication
              dispatch({ type: 'LOGOUT' });
              navigate('/logout');
              //alert('Please sign in again.');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (!loading && !user) {
        // User is signed out
        dispatch({ type: 'LOGOUT' });
        navigate('/logout');
      }
    });

    return () => unsubscribe(); // Unsubscribe from the onAuthStateChanged listener when the component unmounts
  }, [loading, user?.organization]);

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
