import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import authReducer from './reducers/authReducer';
import organizationReducer from './reducers/organizationReducer';
import sketchReducer from './reducers/sketchReducer';
import middleware from './middleware';
// Combine multiple reducers if needed
const rootReducer = combineReducers({
  auth: authReducer,
  organization: organizationReducer,
  sketch: sketchReducer
});

// Configure middleware
//const middleware = [thunk];

// Create the Redux store
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(middleware)));

export default store;
