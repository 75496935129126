import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Dropzone from 'react-dropzone';
import { useAuthContext, setUser } from '../../../contexts/AuthContext';
import { saveCustomizationSettings, getUsersOrganization, saveLogoToOrganization } from '../../../api/user';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
const { notify } = require('../../../utils/notification');

export default function Customization() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [settings, setSettings] = useState({});

  useEffect(async () => {
    // get organization settings
    auth.user &&
      (await getUsersOrganization(auth.user.ref).then((e) => {
        setSettings(e);
      }));
  }, [auth.user]);

  const onBlurSaveSettings = async (e) => {
    //const { error, response } = await saveUserJobNimbusIntegrationSettings(auth.user.ref, { ...settings, apiKey: e.target.value, enabled: true });

    await saveCustomizationSettings(auth.user.ref, settings)
      .then((res) => {
        dispatch({ type: 'UPDATE_ORGANIZATION', data: { ...settings, color: settings.color } });
        //dispatch(setUser({ ...auth.user, organizationSettings: { ...settings, color: settings.color } }));
        notify('success', 'Success', 'Organization color updated');
      })
      .catch((err) => {
        console.log(err);
        setSettings({});
      });
  };

  return (
    <div className="justify-center px-10 py-5">
      <Typography variant="h1">Branding settings</Typography>
      <div className="mb-3 w-full">
        <Typography
          variant="h2"
          mb={1}
          mt={1}
        >
          Company Logo
        </Typography>
        <div className="inline-flex items-center">
          <Dropzone
            onDrop={(acceptedFiles) => {
              const blob = new Blob([acceptedFiles[0]], { type: acceptedFiles[0].type });
              console.log(blob);
              saveLogoToOrganization(auth.user.ref, blob).then((res) => {
                console.log(res);
                setSettings({ ...settings, logo: res });
                dispatch({ type: 'UPDATE_ORGANIZATION', data: { ...settings, logo: res } });
                //dispatch(setUser({ ...auth.user, organizationSettings: { ...settings, logo: res } }));
                notify('success', 'Success', 'Organization logo updated');
              });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />

                <Button
                  variant="contained"
                  sx={{ marginRight: '1.25rem' }}
                >
                  Upload Logo
                </Button>
              </div>
            )}
          </Dropzone>
          <img
            src={settings?.logo}
            width={100}
          />
        </div>

        <Typography
          variant="h2"
          mb={1}
          mt={1}
        >
          Company Color
        </Typography>
        <input
          id="apiKeyInput"
          defaultValue={settings?.color}
          onBlur={onBlurSaveSettings}
          onChange={(e) => setSettings({ ...settings, color: e.target.value })}
          type="text"
          className="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700
         focus:bg-white 
         focus:border-header 
         focus:outline-header 
         focus:shadow-none
      "
          placeholder="#ED5521"
        />
      </div>
    </div>
  );
}
