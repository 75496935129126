import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './components/Sidebar';

export default function Settings() {
  return (
    <div
      id="Settings"
      className="w-full pt-[64px]"
    >
      <Sidebar />
      <div style={{ paddingLeft: '208px' }}>
        <Outlet />
      </div>
    </div>
  );
}
