import LineWithDragHandles from './LineWithDragHandles.js';

export default function TearOutFence(props) {
  return (
    <LineWithDragHandles
      {...props}
      stroke={'red'}
      strokeWidth={5}
      dash={[5, 2]}
      type={'Tear-Out'}
      textColor={'#FF0000'}
    />
  );
}
