// Example authReducer.js

import { SET_OBJECTS } from '../actions/organizationActions';
import { batchUpdate } from 'react-konva';

// Initial state
const initialState = {
  history: [[]],
  historyStep: 0,
  contact: null,
  saveData: {},
  gridVisible: true,
  gridSize: 20,
  legendVisible: true
};

// Reducer function
const sketchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_OBJECT_TO_SAVE_DATA':
      return {
        ...state,
        saveData: { ...action.prevState.sketch.saveData, ...action.data },
        history: [...state.history.slice(0, state.historyStep + 1), [{ ...action.prevState.sketch.saveData, ...action.data }]],
        historyStep: state.historyStep + 1
      };

    case 'DELETE_OBJECT_FROM_SAVE_DATA':
      let newState = { ...action.prevState.sketch.saveData };
      delete newState[action.data];
      //console.log({ ...state, saveData: newState });

      return {
        ...state,
        saveData: newState
      };
    case 'CLEAR_SAVE_DATA':
      let keepStageRef = Object.keys(state.saveData).reduce((acc, key) => {
        if (key === 'stageRef') {
          acc[key] = state.saveData[key];
        }
        return acc;
      }, {});

      return {
        ...state,
        saveData: keepStageRef
      };
    case 'SET_SAVE_DATA':
      return {
        ...state,
        saveData: action.data
      };
    case 'UPDATE_STAGE_REF':
      return {
        ...state,
        saveData: { ...action.prevState.sketch.saveData, stageRef: action.data }
      };

    case 'UNDO':
      // For 'UNDO' action, decrement the history step if possible
      const prevHistoryStep = state.historyStep - 1;
      const prevHistory = state.history[prevHistoryStep];
      // console.log('state history:', state.history);
      // console.log('previous history:', prevHistory);

      if (prevHistory.length === 0) {
        const loadObjectsEvent = new CustomEvent('loadObjects', {
          detail: { data: [] }
        });
        document.dispatchEvent(loadObjectsEvent);
        return { ...state, objects: [], saveData: {}, historyStep: prevHistoryStep };
      }

      if (prevHistory.length > 0) {
        const undoData = saveDataObjectToArray(prevHistory[0]);

        const revertedSaveData = { ...prevHistory[0] };

        // Dispatch event to redraw stage
        const loadObjectsEvent = new CustomEvent('loadObjects', {
          detail: { data: undoData }
        });
        document.dispatchEvent(loadObjectsEvent);

        return {
          ...state,
          saveData: revertedSaveData,
          historyStep: prevHistoryStep
        };
      }

      return state;

    case 'REDO':
      // For 'REDO' action, increment the history step if possible
      const nextHistoryStep = state.historyStep + 1;
      const nextHistory = state.history[nextHistoryStep];
      // console.log('state history:', state.history);
      // console.log('next history:', nextHistory);

      if (nextHistory.length > 0) {
        const redoData = saveDataObjectToArray(nextHistory[0]);
        // console.log('redo data:', redoData);
        const updatedSaveData = { ...nextHistory[0] };

        // Dispatch event to redraw stage
        const loadObjectsEvent = new CustomEvent('loadObjects', {
          detail: { data: redoData }
        });
        document.dispatchEvent(loadObjectsEvent);

        return {
          ...state,
          saveData: updatedSaveData,
          historyStep: nextHistoryStep
        };
      }

      return state;
    case 'SET_CONTACT':
      return {
        ...state,
        contact: action.data
      };
    case 'SET_GRID':
      return {
        ...state,
        gridVisible: action.data
      };
    case 'SET_LEGEND':
      return {
        ...state,
        legendVisible: action.data
      };
    default:
      return state;
  }
};

export default sketchReducer;

const saveDataObjectToArray = (saveData) => {
  let newSaveData = [];
  const keys = Object.keys(saveData);
  //const keys = Object.keys(sketch.objects);
  // console.log(objects);
  // console.log(keys);
  for (var i = 0; i < keys.length; i++) {
    if (keys[i] === 'stageRef') {
      //skip
    } else {
      newSaveData.push(saveData[keys[i]]);
    }
  }
  //console.log(newSaveData);
  return newSaveData;
};
