import LineWithDragHandles from './LineWithDragHandles.js';

export default function ExistingFence(props) {
  return (
    <LineWithDragHandles
      {...props}
      stroke={'black'}
      strokeWidth={5}
      dash={[5, 2]}
      type={'Existing'}
    />
  );
}
