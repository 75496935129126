import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';

function DeleteDrawing({ drawing, deleteDrawing }) {
  const iconStyle = {
    color: '#3F3F3F', // Replace with your desired color
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  };

  return (
    <IconButton
      onClick={() => deleteDrawing(drawing)}
      style={iconStyle}
    >
      <DeleteIcon />
    </IconButton>
  );
}

DeleteDrawing.propTypes = {
  drawing: PropTypes.object,
  deleteDrawing: PropTypes.func
};

export default DeleteDrawing;
