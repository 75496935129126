import { getDoc, updateDoc, doc, addDoc, onSnapshot, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from './firebase';
import { uploadImageToCloudflare } from './cloudflare';
import store from '../store/store';
const { notify } = require('../utils/notification');

const getUser = async (userRef) => {
  try {
    // console.log(id);
    // const docRef = doc(db, 'users', id);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      //console.log('Document data:', docSnap.data());
      // console.log(userSnapshot.data());

      return userSnapshot.data();
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
      throw Error('No such document!');
    }
  } catch (error) {
    return notify('danger', 'Error', `No user found: ${error.message}`);
  }
};

const saveUserJobNimbusIntegrationSettings = async (userDocRef, newSettings) => {
  try {
    const userHasOtherIntegrationEnabled = await userHasAnIntegrationEnabled(userDocRef, 'jobNimbus');

    if (userHasOtherIntegrationEnabled) throw Error('You must disable all other integrations before connecting.');
    console.log(newSettings);
    const user = await getDoc(userDocRef);
    const settings = user.data().jobNimbusIntegration;

    await updateDoc(
      userDocRef,
      { jobNimbusIntegration: newSettings }
      // { merge: true }
    );
    notify('success', 'Success', 'Settings saved');
    return { response: 'success' };
  } catch (error) {
    notify('danger', 'Error', `${error.message}`);
    return { error: error.message };
  }
};

const saveUserJobberIntegrationSettings = async (userDocRef, newSettings) => {
  try {
    const userHasOtherIntegrationEnabled = await userHasAnIntegrationEnabled(userDocRef, 'jobber');

    if (userHasOtherIntegrationEnabled) throw Error('You must disable all other integrations before connecting.');

    const user = await getDoc(userDocRef);
    const settings = user.data().jobberIntegration;

    await updateDoc(
      userDocRef,
      { jobberIntegration: newSettings }
      // { merge: true }
    );
    //notify('success', 'Success', 'Settings saved');
    return { response: 'success' };
  } catch (error) {
    notify('danger', 'Error', `${error.message}`);
    return { error: error.message };
  }
};

async function userHasAnIntegrationEnabled(userDocRef, integration) {
  try {
    const user = await getDoc(userDocRef);
    const userData = user.data();

    let integrationsEnabled = [];

    Object.keys(userData).forEach((e, index) => {
      console.log(e.includes(integration));
      if (e.includes('Integration') && userData[e].enabled & !e.includes(integration)) {
        integrationsEnabled.push(userData[e]);
      }
    });

    if (integrationsEnabled.length > 0) {
      return true;
    } else return false;
  } catch (error) {
    console.log(error);
    return notify('danger', 'Error', `${error.message}`);
  }
}

async function userHasOrganizationSetup(userDocRef) {
  try {
    const user = await getDoc(userDocRef);
    const settings = user.data()?.organization || false;

    return settings !== false;
  } catch (error) {
    notify('danger', 'Error', `${error.message}`);
    return { error: error.message };
  }
}

const saveCustomizationSettings = async (userDocRef, newSettings) => {
  const user = await getDoc(userDocRef);

  let userHasOrganization = await userHasOrganizationSetup(userDocRef);

  if (userHasOrganization) {
    const organizationId = user.data().organization;
    console.log(organizationId);
    const organizationRef = doc(db, 'organizations', organizationId);

    await updateDoc(organizationRef, newSettings);
  } else if (!userHasOrganization) {
    const organizationsRef = collection(db, 'organizations');
    const docRef = await addDoc(organizationsRef, {});

    onSnapshot(docRef, async (snap) => {
      let organizationId = snap.id;
      await updateDoc(userDocRef, { organization: organizationId });
      console.log(snap);

      await updateDoc(snap.ref, newSettings);
      return;
    });
  }
};

const saveLogoToOrganization = async (userDocRef, image) => {
  const user = await getDoc(userDocRef);

  let userHasOrganization = await userHasOrganizationSetup(userDocRef);

  if (userHasOrganization) {
    const organizationId = user.data().organization;

    const organizationRef = doc(db, 'organizations', organizationId);
    const docSnap = await getDoc(organizationRef);
    console.log(docSnap.data());

    const imageUrl = await uploadImageToCloudflare(image).then(async (res) => {
      let shareableUrl = `https://imagedelivery.net/9Nt51ve_BjQZ7bVcK-tkLA/${res.id}/anim=false,format=webp,quality=100,metadata=keep`;
      await updateDoc(organizationRef, { ...docSnap.data(), logo: shareableUrl });
      return shareableUrl;
    });
    return imageUrl;
  } else if (!userHasOrganization) {
    const organizationsRef = collection(db, 'organizations');
    const docRef = await addDoc(organizationsRef, {});

    const imageUrl = await uploadImageToCloudflare(image).then(async (res) => {
      let shareableUrl = `https://imagedelivery.net/9Nt51ve_BjQZ7bVcK-tkLA/${res.id}/anim=false,format=webp,quality=100,metadata=keep`;

      return shareableUrl;
    });

    onSnapshot(docRef, async (snap) => {
      let organizationId = snap.id;
      await updateDoc(userDocRef, { organization: organizationId });
      await updateDoc(snap.ref, { logo: imageUrl });

      return;
    });
    return imageUrl;
  }
};

async function getUsersOrganization(userDocRef) {
  try {
    const user = await getDoc(userDocRef);
    // console.log(user.data());
    const organizationId = user.data()?.organization;
    if (!organizationId) return {};
    const organizationRef = doc(db, 'organizations', organizationId);
    const docSnap = await getDoc(organizationRef);
    let organizationData = docSnap.data();

    if (!organizationData.members || organizationData.members.length === 0) {
      // If the organization has no members or data, set the current user as owner and member
      organizationData = {
        owner: user.data()?.email,
        members: [user.data()?.email]
      };
      await updateDoc(organizationRef, organizationData, { merge: true });
    }

    if (!organizationData.owner) {
      // If the organization has no members or data, set the current user as owner and member
      organizationData = {
        owner: user.data()?.email
      };
      await updateDoc(organizationRef, organizationData, { merge: true });
    }

    if (!organizationData.domain) {
      const userEmail = user.data().email;
      const userEmailDomain = userEmail.split('@')[1];

      if (!genericDomains.includes(userEmailDomain)) {
        // If the organization has no members or data, set the current user as owner and member
        organizationData = {
          domain: userEmailDomain
        };
        await updateDoc(organizationRef, organizationData, { merge: true });
      }
    }

    // Fetch user data for each member in the organization
    const memberUserPromises = organizationData.members.map(async (memberEmail) => {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', memberEmail));
      const querySnapshot = await getDocs(q);
      const userData = querySnapshot.docs[0]?.data();
      return userData;
    });

    const memberUsers = await Promise.all(memberUserPromises);

    // Update the organizationData with the full user data of each member
    organizationData.members = memberUsers;
    //console.log(organizationData);
    return organizationData;
  } catch (error) {
    notify('danger', 'Error', `${error.message}`);
    console.log(error);
    return { error: error.message };
  }
}

export {
  getUser,
  saveUserJobNimbusIntegrationSettings,
  saveUserJobberIntegrationSettings,
  saveCustomizationSettings,
  getUsersOrganization,
  saveLogoToOrganization
};

const genericDomains = [
  //'gmail.com',
  'outlook.com',
  'yahoo.com',
  'aol.com',
  'hotmail.com',
  'icloud.com',
  'mail.com',
  'gmx.com',
  'protonmail.com',
  'zoho.com',
  'yandex.com',
  'live.com',
  'comcast.net',
  'cox.net',
  'me.com',
  'att.net',
  'verizon.net',
  'rocketmail.com',
  'sbcglobal.net',
  'earthlink.net',
  'mail.ru',
  'inbox.com',
  'gmx.net',
  'bellsouth.net',
  'optonline.net',
  'web.de',
  'rediffmail.com',
  't-online.de',
  'free.fr',
  'qq.com',
  '163.com',
  'sina.com',
  'naver.com',
  'hanmail.net',
  'daum.net',
  'rambler.ru',
  'onet.pl',
  'onet.eu',
  'onet.com',
  'onet.com.pl',
  'globo.com',
  'terra.com.br',
  'bol.com.br',
  'uol.com.br',
  'ig.com.br',
  'yahoo.com.br',
  'r7.com',
  'hotmail.com.br',
  'yahoo.co.uk',
  'btinternet.com',
  'virginmedia.com',
  'blueyonder.co.uk',
  'ntlworld.com',
  'talktalk.co.uk',
  'sky.com',
  'libero.it',
  'fastwebnet.it',
  'tin.it',
  'alice.it',
  'virgilio.it',
  'tiscali.it',
  'telecomitalia.it',
  'gmail.de',
  'gmx.de',
  't-online.de',
  'yahoo.de',
  'web.de',
  'aol.de',
  'arcor.de',
  'hotmail.de',
  't-online.com',
  'mac.com',
  'yahoo.fr',
  'hotmail.fr',
  'laposte.net',
  'wanadoo.fr',
  'orange.fr',
  'yahoo.co.jp',
  'hotmail.co.jp',
  'yahoo.co.in',
  'rediffmail.com',
  'yahoo.in',
  'indiatimes.com',
  'hotmail.co.in',
  'yahoo.com.au',
  'yahoo.com.sg',
  'yahoo.com.ph',
  'yahoo.com.my',
  'yahoo.com.vn',
  'yahoo.com.tw',
  'yahoo.co.id',
  'yahoo.com.ar',
  'yahoo.com.mx',
  'yahoo.ca',
  'yahoo.com.tr',
  'yahoo.com.co',
  'yahoo.com.pe',
  'yahoo.com.ve',
  'yahoo.com.ec',
  'yahoo.com.br',
  'yahoo.cl',
  'yahoo.com.co',
  'yahoo.com.sv',
  'yahoo.com.gt',
  'yahoo.com.ni',
  'yahoo.com.hn',
  'yahoo.com.pr',
  'yahoo.com.do',
  'yahoo.com.py',
  'yahoo.com.bo',
  'yahoo.com.uy',
  'yahoo.com.ar',
  'yahoo.com.cu',
  'yahoo.com.cw',
  'yahoo.com.tt',
  'yahoo.com.bz',
  'yahoo.com.ag',
  'yahoo.com.dm',
  'yahoo.com.vc',
  'yahoo.com.ky',
  'yahoo.com.jm',
  'yahoo.com.gi',
  'yahoo.com.mt',
  'yahoo.com.cy',
  'yahoo.com.gr',
  'yahoo.com.mk',
  'yahoo.com.ru',
  'yahoo.com.ua',
  'yahoo.com.kz',
  'yahoo.com.tm',
  'yahoo.com.uz',
  'yahoo.com.kg',
  'yahoo.com.tj',
  'yahoo.com.by',
  'yahoo.com.md',
  'yahoo.com.am',
  'yahoo.com.az',
  'yahoo.com.ge',
  'yahoo.com.ye',
  'yahoo.com.ps',
  'yahoo.com.sy',
  'yahoo.com.lb',
  'yahoo.com.jo',
  'yahoo.com.iq',
  'yahoo.com.sa',
  'yahoo.com.om',
  'yahoo.com.bh',
  'yahoo.com.qa',
  'yahoo.com.kw',
  'yahoo.com.ae',
  'yahoo.com.tr',
  'yahoo.com.ir',
  'yahoo.com.pk',
  'yahoo.com.af',
  'yahoo.com.np',
  'yahoo.com.bd',
  'yahoo.com.lk',
  'yahoo.com.mm',
  'yahoo.com.cn',
  'yahoo.com.hk',
  'yahoo.com.mo',
  'yahoo.com.tw',
  'yahoo.com.sg',
  'yahoo.com.my',
  'yahoo.com.ph',
  'yahoo.com.vn',
  'yahoo.com.id',
  'yahoo.com.kh',
  'yahoo.com.bn',
  'yahoo.com.au',
  'yahoo.com.fj',
  'yahoo.com.sb',
  'yahoo.com.vu',
  'yahoo.com.to',
  'yahoo.com.ws',
  'yahoo.com.ki',
  'yahoo.com.nr',
  'yahoo.com.tl',
  'yahoo.com.pg',
  'yahoo.com.as',
  'yahoo.com.ck',
  'yahoo.com.tv',
  'yahoo.com.wf',
  'yahoo.com.wm',
  'yahoo.com.ug',
  'yahoo.com.zm',
  'yahoo.com.na',
  'yahoo.com.zw',
  'yahoo.com.co',
  'yahoo.com.uy',
  'yahoo.com.br',
  'yahoo.com.cu',
  'yahoo.com.tt',
  'yahoo.com.bz',
  'yahoo.com.ag',
  'yahoo.com.dm',
  'yahoo.com.vc',
  'yahoo.com.ky',
  'yahoo.com.jm',
  'yahoo.com.gi',
  'yahoo.com.mt',
  'yahoo.com.cy',
  'yahoo.com.gr',
  'yahoo.com.mk',
  'yahoo.com.ru',
  'yahoo.com.ua',
  'yahoo.com.kz',
  'yahoo.com.tm',
  'yahoo.com.uz',
  'yahoo.com.kg',
  'yahoo.com.tj',
  'yahoo.com.by',
  'yahoo.com.md',
  'yahoo.com.am',
  'yahoo.com.az',
  'yahoo.com.ge',
  'yahoo.com.ye',
  'yahoo.com.ps',
  'yahoo.com.sy',
  'yahoo.com.lb',
  'yahoo.com.jo',
  'yahoo.com.iq',
  'yahoo.com.sa',
  'yahoo.com.om',
  'yahoo.com.bh',
  'yahoo.com.qa',
  'yahoo.com.kw',
  'yahoo.com.ae',
  'yahoo.com.tr',
  'yahoo.com.ir',
  'yahoo.com.pk',
  'yahoo.com.af',
  'yahoo.com.np',
  'yahoo.com.bd',
  'yahoo.com.lk',
  'yahoo.com.mm',
  'yahoo.com.cn',
  'yahoo.com.hk',
  'yahoo.com.mo',
  'yahoo.com.tw',
  'yahoo.com.sg',
  'yahoo.com.my',
  'yahoo.com.ph',
  'yahoo.com.vn',
  'yahoo.com.id',
  'yahoo.com.kh',
  'yahoo.com.bn',
  'yahoo.com.au',
  'yahoo.com.fj',
  'yahoo.com.sb',
  'yahoo.com.vu',
  'yahoo.com.to',
  'yahoo.com.ws',
  'yahoo.com.ki',
  'yahoo.com.nr',
  'yahoo.com.tl',
  'yahoo.com.pg',
  'yahoo.com.as',
  'yahoo.com.ck',
  'yahoo.com.tv',
  'yahoo.com.wf',
  'yahoo.com.wm',
  'yahoo.com.ug',
  'yahoo.com.zm',
  'yahoo.com.na',
  'yahoo.com.zw',
  'yahoo.com.co',
  'yahoo.com.uy',
  'yahoo.com.br',
  'yahoo.com.cu',
  'yahoo.com.tt',
  'yahoo.com.bz',
  'yahoo.com.ag',
  'yahoo.com.dm',
  'yahoo.com.vc',
  'yahoo.com.ky',
  'yahoo.com.jm',
  'yahoo.com.gi',
  'yahoo.com.mt',
  'yahoo.com.cy',
  'yahoo.com.gr',
  'yahoo.com.mk',
  'yahoo.com.ru',
  'yahoo.com.ua',
  'yahoo.com.kz',
  'yahoo.com.tm',
  'yahoo.com.uz',
  'yahoo.com.kg',
  'yahoo.com.tj',
  'yahoo.com.by',
  'yahoo.com.md',
  'yahoo.com.am',
  'yahoo.com.az',
  'yahoo.com.ge',
  'yahoo.com.ye',
  'yahoo.com.ps',
  'yahoo.com.sy',
  'yahoo.com.lb',
  'yahoo.com.jo',
  'yahoo.com.iq',
  'yahoo.com.sa',
  'yahoo.com.om',
  'yahoo.com.bh',
  'yahoo.com.qa',
  'yahoo.com.kw',
  'yahoo.com.ae',
  'yahoo.com.tr',
  'yahoo.com.ir',
  'yahoo.com.pk',
  'yahoo.com.af',
  'yahoo.com.np',
  'yahoo.com.bd',
  'yahoo.com.lk',
  'yahoo.com.mm',
  'yahoo.com.cn',
  'yahoo.com.hk',
  'yahoo.com.mo',
  'yahoo.com.tw',
  'yahoo.com.sg',
  'yahoo.com.my',
  'yahoo.com.ph',
  'yahoo.com.vn',
  'yahoo.com.id',
  'yahoo.com.kh',
  'yahoo.com.bn',
  'yahoo.com.au',
  'yahoo.com.fj',
  'yahoo.com.sb',
  'yahoo.com.vu',
  'yahoo.com.to',
  'yahoo.com.ws',
  'yahoo.com.ki',
  'yahoo.com.nr',
  'yahoo.com.tl',
  'yahoo.com.pg',
  'yahoo.com.as',
  'yahoo.com.ck',
  'yahoo.com.tv',
  'yahoo.com.wf',
  'yahoo.com.wm',
  'yahoo.com.ug',
  'yahoo.com.zm',
  'yahoo.com.na',
  'yahoo.com.zw'
];
