import { query, collection, where, getDocs, doc, addDoc, onSnapshot } from 'firebase/firestore';
import getStripe from '../stripe/initializeStripe';
import { db } from '../firebase';

export default async function createCheckoutSession(userEmail, priceId) {
  console.log(userEmail, priceId);
  const stripeCustomer = await getDocs(query(collection(db, 'customers'), where('email', '==', userEmail))).then((querySnapshot) => {
    return querySnapshot.docs[0];
  });
  console.log(stripeCustomer);
  const customerRef = doc(db, 'customers', stripeCustomer.id);

  const checkoutSessionsRef = collection(customerRef, 'checkout_sessions');

  const docRef = await addDoc(checkoutSessionsRef, {
    price: priceId,
    allow_promotion_codes: true,
    success_url: window.location.origin + '/settings/billing',
    cancel_url: window.location.origin + '/settings/billing'
  });
  console.log(docRef);
  return onSnapshot(docRef, async (snap) => {
    const { sessionId } = snap.data();
    console.log(sessionId);
    if (sessionId) {
      const stripe = await getStripe();
      // We have a session, let's redirect to Checkout
      stripe.redirectToCheckout({ sessionId });
    }
  });
}
