import { useRef, useEffect, useState } from 'react';
import { Group, Circle, Transformer, Arrow } from 'react-konva';
import { Html } from 'react-konva-utils';
import { styled } from '@mui/material/styles';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';

const ContextMenu = styled('div')`
  display: none;
  position: absolute;
  width: 220px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 3px;
`;

const ContextMenuButton = styled('button')`
  width: 100%;
  background-color: white;
  border: none;
  margin: 0;
  padding: 10px;
  :hover {
    background-color: lightgray;
  }
`;

export default function ArrowTool(props) {
  const [showTransformer, setShowTransformer] = useState(false);
  const [selected, setSelected] = useState(false);
  const stageRef = props.stageRef;
  const points = props.points;
  const x = props.x;
  const y = props.y;
  const arrowRef = useRef(null);
  const transformerRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    document.addEventListener('clearSelections', onClearSelections);

    return () => {
      document.removeEventListener('clearSelections', onClearSelections);
    };
  }, []);

  useEffect(() => {
    save();
  }, []);

  const onClearSelections = (e) => {
    const target = e.detail.target;
    const hasParent = Boolean(target.parent);
    let hasNodes = false;
    if (hasParent) {
      hasNodes = Boolean(target.parent._nodes);
    }

    if (target === arrowRef.current) {
      // setSelected(true);
      // setShowTransformer(true);
      // props.setSelected(circleRef);
      //skip
    } else if (hasParent && hasNodes && target.parent._nodes[0] === arrowRef.current) {
      //skip
    } else {
      setSelected(false);
      setShowTransformer(false);
    }
  };

  useEffect(() => {
    if (showTransformer) {
      transformerRef.current.nodes([arrowRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [showTransformer]);

  const onSelect = (e) => {
    if (e.evt.button === 0 || e.evt.touches) {
      setShowTransformer(true);
      setSelected(true);
      props.setSelected(arrowRef);
    }
  };

  const onTap = (e) => {
    if (e.evt.touches.length > 1) {
      //onContextMenu(e);
    } else {
      onSelect(e);
    }
  };

  const onDragEnd = (e) => {
    save();
  };

  const onTransformEnd = (e) => {
    save();
  };

  const save = () => {
    if (props.setSaveData) {
      props.setSaveData({
        [props.id]: {
          id: props.id,
          key: props.id,
          type: 'Arrow',
          x: arrowRef.current.attrs?.x,
          y: arrowRef.current.attrs?.y,
          points: arrowRef.current.attrs.points,
          radius: arrowRef.current.attrs.radius
        }
      });
    }
  };

  return (
    <>
      <Arrow
        {...props}
        points={points}
        fill={'#4D4D22'}
        stroke={'#4D4D22'}
        strokeWidth={5}
        onClick={onSelect}
        ref={arrowRef}
        draggable={showTransformer}
        strokeScaleEnabled={false}
        //onTap={onContextMenu}
        //onContextMenu={onContextMenu}
        onTouchStart={onTap}
        hitStrokeWidth={20}
        onDragEnd={onDragEnd}
        onTransformEnd={onTransformEnd}
        //shadowColor={'#ED5521'}
        //shadowBlur={10}
        shadowEnabled={selected}
      />
      {showTransformer && <Transformer ref={transformerRef} />}
      {/* <Html>
        <ContextMenu
          id="menu"
          ref={menuRef}
        >
          {selected && <ContextMenuButton onClick={onClickDelete}>Delete</ContextMenuButton>}
        </ContextMenu>
      </Html> */}
    </>
  );
}
