import React, { createContext, useReducer, useContext } from 'react';

export const ToolContext = createContext();

// Initial state
const INITIAL_STATE = {
  tool: 'selector'
};

// Actions
export const SET_TOOL = 'SET_TOOL';

// Action creators
export function setTool(data) {
  return { type: SET_TOOL, data: data };
}

// Reducer
export function toolReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_TOOL:
      console.log(action.data);
      return { ...state, tool: action.data };
    default:
      return state;
  }
}

function ToolProvider(props) {
  const [tool, dispatch] = useReducer(toolReducer, INITIAL_STATE);

  const toolData = { tool, dispatch };

  return (
    <ToolContext.Provider
      value={toolData}
      {...props}
    />
  );
}

function useToolContext() {
  return useContext(ToolContext);
}

export { ToolProvider, useToolContext };
