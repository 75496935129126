// Example authReducer.js

import { SET_ORGANIZATION, UPDATE_ORGANIZATION } from '../actions/organizationActions';

// Initial state
const initialState = {
  name: null,
  logo: null,
  color: null,
  loading: true
};

// Reducer function
const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ORGANIZATION':
      return {
        ...state,
        ...action.data
      };
    case 'UPDATE_ORGANIZATION':
      return {
        ...state,
        organization: action.data
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.data
      };
    default:
      return state;
  }
};

export default organizationReducer;
